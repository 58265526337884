<template>
	<div class="overflow-x-hidden  pt-3 px-5">
		<div class="flex justify-between items-center">
			<h1 class="title">Teams</h1>
			<div class="flex items-center">
				<button class="btnprimary mr-2" @click="addNewTeam()">Add</button>
				<button @click="filterOpen" class="flex" :class="teamsDataArray.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3 fontSizeInPage">
				The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified
				and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.
		</div>
		<div>
			<newTable :tableRowData="teamsDataArray" :headersData="headers1" :openPopup="true"></newTable>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>
		<popup v-if="showTeamsPopUp" :popUp="showTeamsPopUp">
            <template v-slot:header>
                <div class="flex items-center  justify-between py-4 px-5 rounded">
                    <h1>Teams</h1>
                    <button
                        class="float-right text-3xl"
                        @click="onCancelButton('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
			<fieldset>
                <div>
                    <div class="statuscontainer pt-3 px-5">
						<div class="formcontainer flex flex-col">
							<div class="row flex flex-row w-full">
								<div class="w-6/12 mr-2">
									<label class="controllabel font-semibold">Team Name</label>
									<input
										type="text"
										class="inputboxstyling labelMarginTop"
										v-model.trim="mainTeamObject.teamName"
										placeholder="Team Name"
										:class="{
											'is-invalid': v$.mainTeamObject.teamName.$error
										}"
										:disabled ="editTeam == true"
									/>
									<div v-if="v$.mainTeamObject.teamName.$error" class="text-red-500">
										<div class="error-text" v-if="v$.mainTeamObject.teamName.required.$invalid">Required</div>
									</div>
								</div>
								<div v-if="isGlobalObj == true" class="w-6/12 ml-2">
									<label class="controllabel font-semibold">Global Head</label>
									<multiselect 
                                            v-model="mainTeamObject.globalHead" 
                                            :options="userOptions"
											@search-change="asyncFind"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Global Head"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.mainTeamObject.globalHead.$error
                                            }"
                                        >
                                        </multiselect>
									<div v-if="v$.mainTeamObject.globalHead.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.mainTeamObject.globalHead.required.$invalid">Required</div>
                                    </div>
								</div>
							</div>
							<div v-if="mainTeamObject.type == 20301 || (mainTeamObject.type == '')" class="row flex flex-row items-center justify-between w-full my-3 h-8">
								<div class="flex">
									<input type="checkbox" id="isGlobal" :class="globalReseting" v-model="isGlobalObj" class="checkbox"/>
									<label class="font-extrabold text-xs cursor-pointer pl-2" for="isGlobal">Is this Global dept</label>
								</div>
							</div>
								<div v-if="(isGlobalObj == false && editTeam == false) || (isGlobalObj == false && editTeam == true && mainTeamObject.type == 20301)" class="w-full">
									<div>
										<label class="controllabel font-semibold">Entities</label>
										<multiselect 
												v-model="mainTeamObject.entities" 
												:options="bpOptions"
												@search-change="asyncFindBP"
												:showNoOptions="false"
												:multiple="true"
												:hide-selected="true"
												:close-on-select="false"
												:clear-on-select="true"
												open-direction="bottom"
												label="label"
												track-by="value"    
												:searchable="true"
												:hideArrow="false"
												placeholder="Entities"
												class="custom-multiselect userSelect"
												:class="{
													'is-invalid borderRed':  v$.mainTeamObject.entities.$error
												}"
											>
											</multiselect>
											<div v-if="v$.mainTeamObject.entities.$error" class="text-red-500">
												<div class="error-text" v-if="v$.mainTeamObject.entities.required.$invalid">Required</div>
											</div>
									</div>
								</div>
								<div v-else-if="isGlobalObj == false && editTeam == true && mainTeamObject.type == 20302" class="w-full mt-3">
									<div>
										<label class="controllabel font-semibold">Entity</label>
										<multiselect 
												v-model="mainTeamObject.entity" 
												:options="bpOptions"
												@search-change="asyncFindBP"
												:showNoOptions="false"
												:multiple="false"
												:hide-selected="true"
												:close-on-select="true"
												:clear-on-select="true"
												open-direction="bottom"
												label="label"
												track-by="value"    
												:disabled="mainTeamObject.globalHead == undefined"
												:searchable="true"
												:hideArrow="false"
												placeholder="Entity"
												class="custom-multiselect userSelect"
											>
											</multiselect>
											<!-- <div v-if="v$.mainTeamObject.entities.$error" class="text-red-500">
												<div class="error-text" v-if="v$.mainTeamObject.entities.required.$invalid">Required</div>
											</div> -->
									</div>
								</div>
								<div v-if="isGlobalObj == false && editTeam == true && mainTeamObject.globalHead == undefined && mainTeamObject.type == 20302" class="w-6/12 mt-3">
									<label class="controllabel font-semibold">Owner</label>
									<multiselect 
                                            v-model="mainTeamObject.teamOwner" 
                                            :options="userOptions"
											@search-change="asyncFind"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.mainTeamObject.teamOwner.$error
                                            }"
                                        >
                                        </multiselect>
									<div v-if="v$.mainTeamObject.teamOwner.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.mainTeamObject.teamOwner.required.$invalid">Required</div>
                                    </div>
								</div>
						</div>
                    </div>
                    <div v-if="editTeam == false" class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                        <button class="mr-1.5" :class="(JSON.stringify(dummyTeamObj)==JSON.stringify(mainTeamObject))?'btndisabled pointer-events-none':'btnprimary'" @click="onCancelButton('cancel','add')" >Cancel</button>
                        <button :class="(JSON.stringify(dummyTeamObj)==JSON.stringify(mainTeamObject))?'btndisabled pointer-events-none':'btnprimary'" :disabled="clickOnButton" @click="SaveTeamData('add',mainTeamObject)" >Save</button>
                    </div>
					<div v-if="editTeam == true" class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                        <button class="mr-1.5" :class="(JSON.stringify(dummyTeamObj)==JSON.stringify(mainTeamObject))?'btndisabled pointer-events-none':'btnprimary'" @click="onCancelButton('cancel', 'update')" >Cancel</button>
						<button v-if="editTeam == true" :class="(JSON.stringify(dummyTeamObj)==JSON.stringify(mainTeamObject))?'btndisabled pointer-events-none':'btnprimary'" :disabled="clickOnButton" @click="SaveTeamData('update',mainTeamObject)" >Update</button>
                    </div>
                </div>

            </fieldset>
        </popup>
	</div>
</template>
<style scoped>
.mainTh{
	line-height: 59px;
}
.controllabel{
	font-size: 12px;
}
.labelMarginTop{
	margin-top: 8px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import newTable from '@/components/newTable.vue';
import popup from '@/components/popup.vue'
// import Multiselect from '@vueform/multiselect';
import { emitter, toast } from '@/main';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { requiredIf } from '@vuelidate/validators';
import Multiselect from 'vue-multiselect';
import toolTip from '@/components/toolTip.vue'


export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			headers1: ['teamName','entity','teamOwner'],
			showTeamsPopUp: false,
			businessEntitieDisplayName:[],
			showFilters: false,
			teamsDataArray:[],
			entitiesArray:[],
			optionsUserArray:[],
			mainTeamObject:{
				teamName:'',
				globalHead: '',
				entities:[],
				teamOwner : '',
				type: ''
			},
			dummyTeamObj:{},
			users:[],
			isGlobalObj:false,
			businessEntities:[],
			dummyBusinessEntities:[],
			editTeam:false,
			userOptions : [],
			bpOptions : [],
			globalHeadArray:[],
			allTeamsData : [],
			toolTipActive : false,
			toolTipText : '',
			clickOnButton: false,

		};
	},
	validations(){
        return{
			mainTeamObject:{
				teamName:{required},
				globalHead:{
					required: requiredIf(() => {
						return (this.isGlobalObj == true);
					})
				},
				entities: {
					required: requiredIf(() => {
						return ((this.isGlobalObj == false && this.editTeam == true && this.mainTeamObject.type == 20301) || (this.isGlobalObj == false && this.editTeam == false));
					})
				},	
				teamOwner : {
					required: requiredIf(() => {
						return ((this.isGlobalObj == false && this.editTeam == true && this.mainTeamObject.type == 20302) || (this.isGlobalObj == false && this.editTeam == true && this.mainTeamObject.globalHead == undefined) && (this.mainTeamObject.globalHead != null))
						
					})
				}
			},
        }
    },
	components: {
		newTable,
		popup,
		Multiselect,
		toolTip
	},

	computed: {
		globalReseting(): any {
          if(this.isGlobalObj == true && this.mainTeamObject.entities != undefined && this.editTeam == true){
			// this.mainTeamObject.entity = {}
			delete this.mainTeamObject.entities
		  }
        },
		...mapGetters({ userInfo: 'userInfo' })
		
	},
	methods: {
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		addNewTeam(){
			this.showTeamsPopUp = true;
			this.dummyTeamObj = {...this.mainTeamObject};
			this.editTeam = false;
		},
		viewTeamPopup(comingObj:any , Data:any){
			this.showTeamsPopUp = true;
			this.editTeam = true;

			this.mainTeamObject = {_id:comingObj._id,teamName:'', globalHead:{},entity:[],type:'',teamOwner:{}};
			this.mainTeamObject.teamName = comingObj.teamName;
			if(comingObj.type == 20302){
				if(comingObj.entity != undefined || comingObj.entity != null){
                    this.mainTeamObject.entity = comingObj.entity
					this.mainTeamObject.teamOwner = comingObj.teamOwner
				}else if(comingObj.entities != undefined  && Array.isArray(comingObj.entities)){
					this.mainTeamObject.entity = this.businessEntities.find((newEntity:any)=>{
								return newEntity.value == Data.entityId
					 		})
					this.mainTeamObject.teamOwner = this.optionsUserArray.find((newUser:any)=>{
					return newUser.value == Data.ownerId
					})
				}
				delete this.mainTeamObject.globalHead
				this.mainTeamObject.type = 20302
				console.log('GL',this.mainTeamObject)
			} else if(comingObj.type == 20301){
				this.mainTeamObject.globalHead = comingObj.globalHead;
				console.log('watch globalHead',this.mainTeamObject.globalHead)
                delete this.mainTeamObject.entities
				delete this.mainTeamObject.teamOwner
				this.mainTeamObject.type = 20301
				this.isGlobalObj = true;
			} 

			this.dummyTeamObj = JSON.parse(JSON.stringify(this.mainTeamObject));
		},
		asyncFind(query:any){
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 1){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		asyncFindBP(query:any){
			if(query == ''){
				this.bpOptions = []
			}else if(query.length > 1){
				this.bpOptions = this.dummyBusinessEntities.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		async onCancelButton(status:any , action : any){
			if(status == 'close'){
				this.showTeamsPopUp = false;
				this.mainTeamObject = {teamName:'', globalHead: '',entities:[], type:''};
				this.isGlobalObj = false;
				this.editTeam = false;
			}else if(status == 'cancel'){
				this.mainTeamObject = JSON.parse(JSON.stringify(this.dummyTeamObj));
				// this.isGlobalObj = !this.isGlobalObj
				// if(this.dummyTeamObj.teamName != '' || this.mainTeamObject.globalHead == undefined || this.mainTeamObject.entities != undefined){
				// 	this.isGlobalObj = !this.isGlobalObj
				// }
				if(action == 'add'){
                    this.isGlobalObj = false
				}else if(this.mainTeamObject.entity != undefined && this.mainTeamObject.entity != null && this.mainTeamObject.teamOwner == undefined ){
                    this.isGlobalObj = true
				}		
			}
			this.v$.$reset();			
		},
		async SaveTeamData(status:any, data : any){	
			this.v$.mainTeamObject.$touch();	
			console.log('this.v$.mainTeamObject.$invalid',this.v$.mainTeamObject.$invalid)
			if (!this.v$.mainTeamObject.$invalid) {
				this.clickOnButton = true;
				let payload:any = [];
				if(this.isGlobalObj == false){
					let obj = {...this.mainTeamObject};
					if(this.editTeam == true && this.mainTeamObject.globalHead == undefined && this.mainTeamObject.teamOwner != undefined){
                          obj.teamOwner = obj.teamOwner.value
					      console.log('TR',obj.teamOwner);
					}
					
					if((this.isGlobalObj == false && this.editTeam == false) || (this.isGlobalObj == false && this.editTeam == true && obj.globalHead != undefined) || (this.mainTeamObject.entities != undefined && this.mainTeamObject.globalHead == null)){
						if(obj.entities != undefined || obj.entities != null){
                               let globalHeadArray: any = obj.entities
								const globalHeadIdArray: any[] = []
								globalHeadArray.forEach((obj: any) => {
								globalHeadIdArray.push(obj.value)
							});
							obj.entities = globalHeadIdArray
							delete obj.entity
						}
					}else{
						obj.entity = obj.entity.value
						delete obj.globalHead
					}

					delete obj.globalHead
                    obj.type = 20302;
					console.log('add entity 123456',obj)
					payload.push(obj)
					console.log('add entity',payload)
				}else{
					let obj = {...this.mainTeamObject};
                    obj.globalHead = obj.globalHead.value;
					delete obj.entities
					delete obj.teamOwner
					delete obj.entity
					obj.type = 20301;
					payload.push(obj)
					console.log('GLOBAL',payload)
				}

				if(status == 'add'){
					await this.addTeamData(payload)
				}else{
					let objId:any = this.mainTeamObject._id
					console.log('HIIIIIIII!!11111111111',this.mainTeamObject)
					await this.updateTeamData(objId,payload)
				}			
			}else{
				console.log('kjsd')
				// toast.error(`cannot save`, {
                //     timeout: 5000,
                //     closeOnClick: true,
                //     closeButton: 'button',
                //     icon: true
                // });
				//  this.onCancelButton('close');
			}
		},
		async addTeamData(payload:any){
			try{
               await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/departments/create`,payload).then(async (res: any) => {
				toast.info(`Created`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.onCancelButton('close');
				await this.getAllTeams();
			})
			}catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
				this.clickOnButton = false;
			}
		},
		async updateTeamData(objId:any,payload:any){
			console.log('objId',objId)
			if(payload.type == 20301){
				payload.globalHead = payload.globalHead.value
			}
			delete payload[0]._id
			console.log('updtade',payload)
			try{
                await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/departments/update/${objId}`,payload[0]).then(async (res: any) => {
				toast.info(`Updated`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.onCancelButton('close');
				await this.getAllTeams();
			})
			}catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
				this.clickOnButton = false;
			}
		},
		async getAllTeams(){
			this.teamsDataArray = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				console.log("Res after add", res.data)
				this.allTeamsData = res.data
				res.data.map((teamObj:any)=>{
					if(teamObj.type == 20301){
						// teamObj.globalHead = this.optionsUserArray.find((owner:any)=>{
						// 	return owner.value == teamObj.globalHead
						// })

						 teamObj.globalHead = this.optionsUserArray.find((newglobalHead:any)=>{
					    	return newglobalHead.value == teamObj.globalHead
					     })
					   let teamGlobalHead = teamObj.globalHead != undefined ? teamObj.globalHead.label : ''

						// let ownerOfTeam = teamObj.globalHead != undefined ? teamObj.globalHead.label : 'N/A'
					    let object = {
						'teamName' : teamObj.teamName,
						'entity' : 'Global',
						'teamOwner' : teamGlobalHead != undefined ? teamGlobalHead : 'N/A',
						type : 20301,
						_id : teamObj._id
					}
					
						this.teamsDataArray.push(object)
					}else if(teamObj.type == 20302){
						teamObj.entity = this.businessEntities.find((newEntity:any)=>{
							return newEntity.value == teamObj.entity
						})

					   	let entityName = teamObj.entity != undefined ? teamObj.entity.label : '';
						let entityId = teamObj.entity != undefined ? teamObj.entity.value : ''

					    teamObj.teamOwner = this.optionsUserArray.find((newteamOwner:any)=>{
							return newteamOwner.value == teamObj.teamOwner
						})
					   	let teamsName = teamObj.teamOwner != undefined ? teamObj.teamOwner.label : ''

                        console.log('88888888',teamsName);
					   	let teamName = this.optionsUserArray.find((obj:any)=>{
							return obj.value == teamObj.teamOwner
					   	})

					   if(teamObj.entities == undefined){
							let newObj = {
							'teamName' : teamObj.teamName,
							'entity' : teamObj.entity != undefined ? entityName : 'N/A',
							'teamOwner' : teamsName != undefined ? teamsName : 'N/A',
							type : 20302,
							_id : teamObj._id,
							'entityId': teamObj.entity != undefined ? entityId : 'N/A',
						}

						this.teamsDataArray.push(newObj)

					   }else if(teamObj.entities != undefined && Array.isArray(teamObj.entities)){
						  teamObj.entities.map((obj:any)=>{
							obj.entity = this.businessEntities.find((newEntity:any)=>{
								return newEntity.value == obj.entity
					 		})
							 obj.owner = this.optionsUserArray.find((newUser:any)=>{
								return newUser.value == obj.owner
							 })
					         let newEntity = obj.entity != undefined ? obj.entity.label : 'N/A';
							 let entityId = obj.entity != undefined ? obj.entity.value : 'N/A';
							 let newOwner = obj.owner != undefined ? obj.owner.label : 'N/A';
							 let ownerId = obj.owner != undefined ? obj.owner.value : 'N/A';
									let newObj = {
										entityId : entityId,
										ownerId : ownerId,
										'teamName' : teamObj.teamName,
										'entity' : newEntity,
										'teamOwner' : newOwner,
										type : 20302,
										_id : teamObj._id
								}
							this.teamsDataArray.push(newObj)
						  })
					   }
					 }
				})
			})
			
		},
		async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
            
        },
		async getAllEntities(){
			this.businessEntities = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				res.data.map((obj:any)=>{
					let a = {value:obj._id, label:obj.entityName};
					let b = {value:obj._id, label:obj.entityDisplayName};
					this.businessEntities.push(a);
					console.log('BE',this.businessEntities)
					this.dummyBusinessEntities.push({...a});
					this.businessEntitieDisplayName.push(b)
				})
                             
            })
		},
		async deleteTeams(teamId:any){
			console.log('dataaaa',teamId)
			await this.$http.delete(`${process.env.VUE_APP_ORG_API_URL}/departments/delete/${teamId}`).then(async (res: any) => {
				toast.info(`Team is Deleted`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				await this.getAllTeams();
			})
		},
	},
	async created(){
		await this.getAllUsers();
		await this.getAllEntities();
		await this.getAllTeams();
	},
	async mounted() {
		emitter.off('dataTransfering')
		emitter.on('dataTransfering', (Data: any) => {
			console.log('lkjhsdkjlkjas', Data);
			console.log('allTeamsDataallTeamsData',this.allTeamsData)
			let obj:any;

			if(Data.type == 20301){
				obj = this.allTeamsData.find((team:any)=>{
					return team._id == Data._id
				})
			}else if(Data.type == 20302){
				obj = this.allTeamsData.find((team:any)=>{
					return team._id == Data._id && team.entity.value == Data.entityId
				})
			}
			
			this.viewTeamPopup(obj, Data);
		});

		emitter.off('deleteRowFromTable')
		emitter.on('deleteRowFromTable', (data:any) => {
			// console.log('New',data)
			// let afterDeleteArray = this.teamsDataArray.filter((obj:any)=>{
			// 	return !(data._id == obj._id)
			// });
			// this.teamsDataArray = [...afterDeleteArray]
			// this.deleteTeams(data._id)
			console.log('Delete Method is Called')
        });
		 emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
	}
});
</script> 