import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

import defaultLayout from "@/layouts/defaultLayout.vue";
import emptyLayout from "@/layouts/emptyLayout.vue";
import adminlayout from "@/layouts/adminLayout.vue";
import teams from "@/features/teams.vue";
import usermanagment from "@/features/userManagment.vue";
import apps from "@/features/apps.vue";
import onboarding from "@/features/onboarding.vue";
import Default from "@/features/default.vue";
import regions from '@/features/regions.vue'
import businessEntites from '@/features/businessEntites.vue';
import bussinessProcess from '@/features/bussinessProcess.vue';
import addEntity from '@/features/addEntity.vue'
import vendors from '@/features/vendors.vue';
import labelDefinitions from '@/features/labelDefinitions.vue';
import systems from '@/features/systems.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: "/teams",
        name: "teams",
        meta: {
            title: "Teams",
            layout: defaultLayout,
        },
        component: teams,
        // beforeEnter:(to,from,next)=>{
        //     const userInfo = store.getters.userInfo;
        //     const userRoles = userInfo.roles.map((a:any) => a.roleId);
        //     if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
        //         next()
        //     } else{
        //         next('/apps')
        //     }
        // }
    },
    {
        path: "/users",
        name: "users",
        meta: {
            title: "Users",
            layout: defaultLayout,
        },
        component: usermanagment,
        // beforeEnter:(to,from,next)=>{
        //     const userInfo = store.getters.userInfo;
        //     const userRoles = userInfo.roles.map((a:any) => a.roleId);
        //     if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
        //         next()
        //     } else{
        //         next('/apps')
        //     }
        // }
    },
    {
        path: "/regions",
        name: "regions",
        meta: {
            title: "regions",
            layout: defaultLayout,
        },
        component: regions,
        // beforeEnter:(to,from,next)=>{
        //     const userInfo = store.getters.userInfo;
        //     const userRoles = userInfo.roles.map((a:any) => a.roleId);
        //     if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
        //         next()
        //     } else{
        //         next('/apps')
        //     }
        // }
    },
    {
        path: "/region/:regionId/businessEntities",
        name: "businessEntitesForRegion",
        meta: {
            title: "entities",
            layout: defaultLayout,
        },
        component: businessEntites,
        // beforeEnter:(to,from,next)=>{
        //     const userInfo = store.getters.userInfo;
        //     const userRoles = userInfo.roles.map((a:any) => a.roleId);
        //     if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
        //         next()
        //     } else{
        //         next('/apps')
        //     }
        // }
    },
    {
        path: "/businessEntites",
        name: "businessEntites",
        meta: {
            title: "businessEntites",
            layout: defaultLayout,
        },
        component: businessEntites,
        // beforeEnter:(to,from,next)=>{
        //     const userInfo = store.getters.userInfo;
        //     const userRoles = userInfo.roles.map((a:any) => a.roleId);
        //     if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
        //         next()
        //     } else{
        //         next('/apps')
        //     }
        // }
    },
    {
        path: "/vendors",
        name: "vendors",
        meta: {
            title: "vendors",
            layout: defaultLayout,
        },
        component: vendors,
    },
    {
        path: "/bussinessProcess",
        name: "bussinessProcess",
        meta: {
            title: "bussinessProcess",
            layout: defaultLayout,
        },
        component: bussinessProcess,
    },
    {
        path: "/labelDefinitions",
        name: "labelDefinitions",
        meta: {
            title: "labelDefinitions",
            layout: defaultLayout,
        },
        component: labelDefinitions,
    },
    {
        path: "/systems",
        name: "systems",
        meta: {
            title: "systems",
            layout: defaultLayout,
        },
        component: systems,
    },
    {
        path: "/addEntity",
        name: "addEntity",
        meta: {
            title: "addEntity",
            layout: defaultLayout,
        },
        component: addEntity,
        // beforeEnter:(to,from,next)=>{
        //     const userInfo = store.getters.userInfo;
        //     const userRoles = userInfo.roles.map((a:any) => a.roleId);
        //     if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
        //         next()
        //     } else{
        //         next('/apps')
        //     }
        // }
    },
    {
        path: "/region/:regionId/addEntity",
        name: "addEntityForRegions",
        meta: {
            title: "addEntity",
            layout: defaultLayout,
        },
        component: addEntity,
    },
    {
        path: "/:entityId/editEntity",
        name: "editEntity",
        meta: {
            title: "editEntity",
            layout: defaultLayout,
        },
        component: addEntity,
        // beforeEnter:(to,from,next)=>{
        //     const userInfo = store.getters.userInfo;
        //     const userRoles = userInfo.roles.map((a:any) => a.roleId);
        //     if(userRoles.includes(10201) || userRoles.includes(10202) || userRoles.includes(10207)){
        //         next()
        //     } else{
        //         next('/apps')
        //     }
        // }
    },
    {
        path: "/region/:regionId/entity/:entityId/editEntity",
        name: "editEntityForRegion",
        meta: {
            title: "editEntityForRegion",
            layout: defaultLayout,
        },
        component: addEntity,
    },
    {
        path: '/',
        meta: {
            title: "Users",
            layout: defaultLayout,
        },
        redirect: { name: 'users' }
    },
    {
    path: '/error',
    name: 'error',
    meta: {
        title: 'error',
        layout: 'empty-layout',
    },
    props:true,
    component: () => import('@/components/error.vue')
    },
    {
    path: '/:pathMatch(.*)*',
    redirect: '/error',
    },
]


const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if(to.meta.title){
        document.title=`Organisation - ${to.meta.title}`
    }else{
        document.title=`Organisation`
    }
   
    store.state.isLoading = true;
    console.log('Router check triggered', to)
    if (to.path == '/users') {
        if (to.query.session) {
            console.log('Nav to default route with',to.query.session)
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ORG_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        await normalAuthenticationCheck(next);
    } else {
        await normalAuthenticationCheck(next);
    }
    // next();
});

router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});


async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if(!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ORG_WEBAPP_URL}`;
    } else{
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if(!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else{
            next();
        }
    }
}


export default router;

