<template>
	<div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-full skeleton mt-4 tableCornerRadius bg-white">
			<dots-loader />
		</div>
	</div>
	<div v-else class="overflow-x-hidden px-5 pt-3">
		<div class="flex justify-between items-center">
			<h1 class="title">Users</h1>
			<div class="flex flex-row-reverse items-center">
				<button class="flex" :class="teamsDataArray.length == 0 ? 'btndisabled pointer-events-none':'filterButton'" @click="filterOpen">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
				<button class="btnprimary mr-2" v-on:click="showPopup(user, 'add')">Add</button>
				<button class="btnprimary mr-2" @click="toggleImportPopup()">Import</button>
				<input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
			</div>
		</div>
		<div class="mt-3">
            <newTable :tableRowData="teamsDataArray" :headersData="headers" :openPopup="false" :navigate="true"></newTable>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
        </div>
		<popup v-if="showUserModal" :popUp="showUserModal">
			<template v-slot:header>
				<div class="flex items-center justify-between py-4 px-5 rounded">
					<h1 v-if="selectedUser._id" class="w-4/5" style="white-space: break-spaces;" :class="headerName.length > 40 ? 'table-ellipsis':''">Update User</h1>
					<h1 v-else>Add User</h1>
					<button class="float-right text-3xl" @click="onClose()">
						<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
					</button>
				</div>
			</template>
			<fieldset>
				<div>
					<div>
						<div class="statuscontainer pt-3 px-5">
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full items-center">
									<div class="w-6/12">
										<label class="controllabel font-semibold">Name</label>
										<input
											type="text"
											class="inputboxstyling fontSizeInpopup labelMarginTop"
											v-model.trim="selectedUser.name"
											placeholder="Name"
											:class="{
												'is-invalid': v$.selectedUser.name.$error
											}"
										/>
										<div v-if="v$.selectedUser.name.$error" class="text-red-500">
											<div class="error-text" v-if="v$.selectedUser.name.required.$invalid">Required</div>
										</div>
									</div>
									<div class="w-6/12 pl-4 mt-2">
										<div class="flex items-end flex-row">
											<label class="controllabel mr-2 mb-2">User status</label>
											<label class="switch ml-5 mt-5">
												<input type="checkbox" :disabled="!selectedUser._id" v-model="selectedUser.isActive" :checked="selectedUser.isActive" />
												<span class="slider round"></span>
											</label>
										</div>
									</div>
								</div>
							</div>
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full mt-3">
									<div class="w-6/12">
										<label class="controllabel font-semibold">Entity</label>
										<multiselect 
                                            v-model="selectedUser.entity" 
											@select="getDepartmentsforEachEntity()"
                                            :options="bpOptions"
											@search-change="asyncFind"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Entity"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.selectedUser.entity.$error
                                            }"
                                        >
                                        </multiselect>
										<div v-if="v$.selectedUser.entity.$error" class="text-red-500">
											<div class="error-text" v-if="v$.selectedUser.entity.required.$invalid">Required</div>
										</div>
									</div>
									<div class="w-6/12 pl-4">
										<label class="controllabel font-semibold">Team</label>
									
										<multiselect 
                                            v-model="selectedUser.departmentId" 
                                            :options="teamOptions"
											@search-change="asyncFindTeams"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
											:disabled="selectedUser.entity == '' || selectedUser.entity == undefined"
                                            placeholder="Team"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.selectedUser.departmentId.$error
                                            }"
                                        >
                                        </multiselect>
										<div v-if="v$.selectedUser.departmentId.$error" class="text-red-500">
											<div class="error-text" v-if="v$.selectedUser.departmentId.required.$invalid">Required</div>
										</div>
									</div>
								</div>
							</div>
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full mt-3">
									<div class="w-6/12">
										<label class="controllabel font-semibold">Job Title</label>
										<input
											type="text"
											class="inputboxstyling fontSizeInpopup labelMarginTop"
											v-model.trim="selectedUser.jobTitle"
											placeholder="Job Title"
											:class="{
												'is-invalid': v$.selectedUser.jobTitle.$error
											}"
										/>
										<div v-if="v$.selectedUser.jobTitle.$error" class="text-red-500">
											<div class="error-text" v-if="v$.selectedUser.jobTitle.required.$invalid">Required</div>
										</div>
									</div>
									<div class="w-6/12 pl-4">
										<label class="controllabel font-semibold">Email</label>
										<div v-if="editPopup == false">
											<input
											type="text"
											class="inputboxstyling fontSizeInpopup labelMarginTop"
											v-model.trim = "selectedUser.email"
											@input = "ModifiedEmail($event)"
											:disabled="selectedUser._id"
                                            placeholder="Email"
											:class="{
												'is-invalid': v$.selectedUser.email.$error || checkEmailUniqueness(selectedUser.email)
											}"
										/>
										<div v-if="v$.selectedUser.email.$error" class="text-red-500">
											<div class="error-text" v-if="v$.selectedUser.email.required.$invalid">Required</div>
											<div v-else-if="v$.selectedUser.email.required && v$.selectedUser.email.email.$invalid && (!checkEmailUniqueness(selectedUser.email))" class="invalid-feedback text-xs">Email is Invalid</div>
											<p v-else-if="v$.selectedUser.email.matchUrl.$invalid && (!checkEmailUniqueness(selectedUser.email))" class="error-text">url and email don't belong to the same domain</p>
										</div>
										<div v-if="checkEmailUniqueness(selectedUser.email)" class="text-red-500">
											<div class="error-text">Email already exists.</div>
										</div>
										</div>
										<div v-if="editPopup == true">
                                             <p class="mt-2">{{ selectedUser.email }}</p>
										</div>
									</div>
								</div>
							</div>

							<h1 class="mt-3">Job Roles</h1>
							<div class="border-b mt-1 border-solid border-lightgrey"></div>

							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full mt-3">
									<div class="w-6/12">
										<label class="controllabel font-semibold">Training</label>
										<select class="inputboxstyling fontSizeInpopup labelMarginTop" v-model="selectedUser.roleTraining" :disabled="!this.editPopup">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="role in roles" :value="role.roleId" :key="role.roleId">
												{{ role.roleName }}
											</option>
										</select>
									</div>
									<div class="w-6/12 pl-4">
										<label class="controllabel font-semibold">Monitor</label>
										<select class="inputboxstyling fontSizeInpopup labelMarginTop" v-model="selectedUser.roleMonitor" :disabled="!this.editPopup">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="role in roles" :value="role.roleId" :key="role.roleId">
												{{ role.roleName }}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full mt-3">
									<div class="w-6/12">
										<label class="controllabel font-semibold">Workflow</label>
										<select class="inputboxstyling fontSizeInpopup labelMarginTop" v-model="selectedUser.roleWorkflow" :disabled="!this.editPopup">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="role in roles" :value="role.roleId" :key="role.roleId">
												{{ role.roleName }}
											</option>
										</select>
									</div>
									<div class="w-6/12 pl-4">
										<label class="controllabel font-semibold">Risk</label>
										<select class="inputboxstyling fontSizeInpopup labelMarginTop" v-model="this.selectedUser.roleRisk" :disabled="!this.editPopup">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="role in roles" :value="role.roleId" :key="role.roleId">
												{{ role.roleName }}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full mt-3">
									<div class="w-6/12">
										<label class="controllabel font-semibold">Audit</label>
										<select class="inputboxstyling fontSizeInpopup labelMarginTop" v-model="selectedUser.roleAudit" :disabled="!this.editPopup">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="role in roles" :value="role.roleId" :key="role.roleId">
												{{ role.roleName }}
											</option>
										</select>
									</div>
									<div class="w-6/12 pl-4">
										<label class="controllabel font-semibold">DMS</label>
										<select class="inputboxstyling fontSizeInpopup labelMarginTop" v-model="selectedUser.roleDMS" :disabled="!this.editPopup">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="role in roles" :value="role.roleId" :key="role.roleId">
												{{ role.roleName }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="buttonposition flex justify-center items-center mb-4  mt-4 mx-0">
						<button :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" class="mr-1.5" @click="cancelBtn(selectedUser)">Cancel</button>
						<button v-if="selectedUser._id" :disabled="clickOnButton" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdateUser(selectedUser)">Update</button>
						<button v-else :disabled="clickOnButton" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdateUser(selectedUser)">Save</button>
					</div>
				</div>
			</fieldset>
		</popup>
	</div>
</template>
<style scoped>
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import pagination from '@/components/pagination.vue';
import popup from '@/components/popup.vue';
import Multiselect from 'vue-multiselect';
import dotsLoader from '../components/dotsLoader.vue';
import { emitter, toast } from '@/main';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';
import * as XLSX from 'xlsx';
import { required, email } from '@vuelidate/validators';
import _ from 'lodash';
import newTable from '@/components/newTable.vue';
import toolTip from '@/components/toolTip.vue';
const matchUrl = (value: any, vm: any) => value.substring(value.indexOf('@') + 1).split('.')[0] === vm.domainName;
export default defineComponent({
    data(): any {
        return {
			showFilters:false,
			teamsDataArray : [],
			v$: useVuelidate(),
			filter: {},
            headers:['name','email','team','jobTitle','status'],
			selectedUser: {
				name: "",
				entity: "",
				email: '',
				departmentId: "",
				jobTitle: ""
			},
			departmentsforEachEntity: [],
			departmentsList: [],
			businessEntities: [],
			editUserObj: [],
			editPopup: false,
			showUserModal: false,
			teamOptions:[],
			defaultUser: {
				uiroles: [10205, 10208],
				isActive: 0
			},
			roles: [
				{
					roleName: 'Admin',
					roleId: 10207
				},
				{
					roleName: 'User',
					roleId: 10208
				}
			],
			headerName: '',
			tenantsList: [],
			orgUsersList: [],
			searchList: [],
			trainingRoles: [
				{
					roleName: 'Training Root Admin',
					roleId: 10206,
					moduleId: 10002,
					disabled: true
				},
				{
					roleName: 'Client Admin',
					roleId: 10207,
					moduleId: 10002
				},
				{
					roleName: 'User',
					roleId: 10208,
					moduleId: 10002
				}
			],
			newImportModal: false,
			selected: '',
			allTeamMembersList : [],
			bpOptions : [],
			originalTeamArray : [],
			toolTipActive : false,
            toolTipText : '',
			userEmails : [],
			selectedEmailId : '',

			clickOnButton: false,
		}
	},
	components: {
		popup,
		Multiselect,
		dotsLoader,
		newTable,
		toolTip
	},
	validations() {
		return {
			selectedUser: {
				name: { required },
				entity: { required },
				email: { required, email, matchUrl },
				departmentId: { required },
				jobTitle: { required }
			}
		};
	},
	computed: {
       ...mapGetters({ userInfo: 'userInfo', dotsLoading: 'dotsLoading' }),
		disableStatus: function (): any {
			let output;
			if (this.editPopup) {
				let objCopy = Object.assign({}, this.selectedUser);
				if (_.isEqual(objCopy, this.editUserObj)) {
					output = false;
				} else {
					output = true;
				}
			} else {
				let val: any = [];
				Object.keys(this.selectedUser).forEach((value: any) => {
					val.push(value);
				});
				for (let key of val) {
					if (key == 'name' || key == 'email' || key == 'jobTitle' || key == 'entity' || key == 'departmentId') {
						if (this.selectedUser[key] != '') {
							output = true;
						}
					}
				}
			}
			return output;
		},
    },
	methods:{
		filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
		getDepartmentsforEachEntity(){
			this.departmentsforEachEntity = [];
			this.selectedUser.departmentId = ''

			this.departmentsList.forEach((obj:any)=>{
				var teamObj = {label: obj.teamName, value: obj._id};
                // console.log('624',obj)
				if(obj.type != undefined && obj.type == 20301){
					this.departmentsforEachEntity.push(teamObj)
				}else if(obj.type != undefined && obj.type == 20302 && obj.entity != undefined){
					let entityids:any = []
					entityids.push(obj.entity)
					entityids.includes(this.selectedUser.entity.value) ? this.departmentsforEachEntity.push(teamObj) : ''
				}else if(obj.type != undefined && obj.type == 20302 && obj.entities != undefined && Array.isArray(obj.entities)){
					// console.log("MULTIPLE ENTITIES")
					let entityids:any = []
					obj.entities.map((obj:any)=>{
						entityids.push(obj.entity)
					})
                    entityids.includes(this.selectedUser.entity.value) ? this.departmentsforEachEntity.push(teamObj) : ''
				}
			})
			// console.log('departmentsforEachEntity',this.departmentsforEachEntity)
		},
		onClose(){
			Object.keys(this.selectedUser).forEach((prop) => {
				if (prop == '_id') {
					delete this.selectedUser[prop];
				} else if (prop != 'roleMonitor' && prop != 'roleRisk' && prop != 'roleTraining') {
					Array.isArray(this.selectedUser[prop]) ? (this.selectedUser[prop] = []) : (this.selectedUser[prop] = '');
				}
			});
			this.departmentsforEachEntity = []
			this.selectedUser.isActive = 0
			this.showUserModal = false;
			this.editPopup = false;
		},
		asyncFindTeams(query:any){
			if(query == ''){
				this.teamOptions = []
			}else if(query.length > 1){
				this.teamOptions = this.departmentsforEachEntity.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		asyncFind(query:any){
			if(query == ''){
				this.bpOptions = []
			}else if(query.length > 1){
				this.bpOptions = this.businessEntities.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        ModifiedEmail(event:any) {
        const input = event.target;
        const start = input.selectionStart
        this.selectedUser.email = event.target.value.trim().toLowerCase();
        this.$nextTick(() => {
            input.setSelectionRange(start, start); 
        });
		},
		showPopup(user: any, action: string) {
			this.editPopup = false;
			console.log("user", user)
			this.v$.$reset();
			this.defaultUser.entity = '';
			this.defaultUser.name = '';
			this.defaultUser.email = '';
			this.defaultUser.departmentId = '';
			this.defaultUser.isActive = 0;
			this.defaultUser.jobTitle = '';
			this.defaultUser.uiroles = 10208;
			if (action == 'edit') {
				console.log("EDIT")
				this.defaultUser.roleMonitor = '';
				this.headerName = user.name;
				this.defaultUser.roleRisk = '';
				this.editPopup = true;
				user.roles.forEach((role: any) => {
					if (role.appId === 10001) {
						user.roleMonitor = role.roleId;
					} else if (role.appId === 10002) {
						user.roleTraining = role.roleId;
					} else if (role.appId === 10003) {
						user.roleRisk = role.roleId;
					} else if (role.appId === 10004) {
						user.roleDMS = role.roleId;
					} else if (role.appId === 10005) {
						user.roleAudit = role.roleId;
					} else if (role.appId === 10006) {
						user.roleWorkflow = role.roleId;
					}
					console.log("departmentlist:",this.departmentsList);

				});
				
				this.departmentsforEachEntity = this.departmentsList
				.filter((obj:any) => obj._id === user.departmentId)
				.map((obj:any) => ({ label: obj.teamName, value: obj._id }));
				let tenantdata = this.tenantsList.filter((tenant: any) => {
					return tenant._id == this.userInfo.organisationId;
				});
				user.domainName = tenantdata[0].tenantName;
				this.selectedUser = { ...user };
				this.editUserObj = { ...this.selectedUser };
				this.getDepartmentsforEachEntity()
			} else if (action == 'add') {
				this.roles.forEach((role: any) => {
					if (role.roleId === 10208){
						this.defaultUser.roleMonitor = role.roleId;
						this.defaultUser.roleTraining = role.roleId;
						this.defaultUser.roleRisk = role.roleId;
						this.defaultUser.roleDMS = role.roleId;
						this.defaultUser.roleAudit = role.roleId;
						this.defaultUser.roleWorkflow = role.roleId;
					}
				});
			}

			this.selectedUser = action == 'add' ? this.defaultUser : { ...user };
			let tenantdata = this.tenantsList.filter((tenant: any) => {
				return tenant._id == this.userInfo.organisationId;
			});
			this.selectedUser.domainName = tenantdata[0].tenantName;
			this.showUserModal = !this.showUserModal;
		},
		async fileReader(oEvent: any) {
			var oFile = oEvent.target.files[0];
			var reader = new FileReader();
			reader.onload = async (e: any) => {
				var data = e.target.result;
				data = new Uint8Array(data);
				var workbook = XLSX.read(data, { type: 'array' });
				/* DO SOMETHING WITH workbook HERE */
				var first_sheet_name = workbook.SheetNames[0];
				/* Get worksheet */
				var worksheet = workbook.Sheets[first_sheet_name];
				var jsonObj: any = XLSX.utils.sheet_to_json(worksheet, {
					raw: true,
					header: ['name', 'email', 'departmentName', 'jobTitle'],
					range: 1
				});
				let total = jsonObj.length;
				let valid = 0;
				var excelFinalDepartments: any = [];
				var departments: any = [];
				var finalarray: any = [];
				jsonObj.forEach((department: any) => {
					if (department.departmentName != '' && department.departmentName != undefined) {
						excelFinalDepartments.push(department.departmentName.toLowerCase());
					}
				});
				this.departmentsList.forEach((team: any) => {
					departments.push(team.teamName.toLowerCase());
				});
				let excelDepartments = [...new Set(excelFinalDepartments)];
				let finaldepartments = [...new Set(departments)];

				var differenceDepartments = excelDepartments.filter((x) => !finaldepartments.includes(x));
				if (differenceDepartments.length > 0) {
					var payload: any = [];
					differenceDepartments.forEach(async (singleteam: any) => {
						payload.push({
							teamName: singleteam,
							teamOwner: 'N/A'
						});
					});
					await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/departments/create`, payload).then(async () => {
						await this.getallTeams();
					});
				}
				for (var i = 0; i < jsonObj.length; i++) {
					for (var j = 0; j < this.departmentsList.length; j++) {
						if (jsonObj[i].name != undefined && jsonObj[i].departmentName != undefined && jsonObj[i].email != undefined) {
							var excelTeamName: any = jsonObj[i].departmentName;
							var teamListTeamname: any = this.departmentsList[j].teamName;
							if (excelTeamName.toLowerCase() == teamListTeamname.toLowerCase()) {
								var updatedObject = Object.assign(jsonObj[i], {
									departmentId: this.departmentsList[j]._id,
									roles: [
										{ appId: 10001, roleId: 10208 },
										{ appId: 10002, roleId: 10208 },
										{ appId: 10003, roleId: 10208 },
										{ appId: 10004, roleId: 10208 },
										{ appId: 10005, roleId: 10208 },
										{ appId: 10006, roleId: 10208 }
									],
									isActive: 0
								});
								finalarray.push(updatedObject);
							}
						}
					}
				}

				this.orgUsersList;
				let users = finalarray.filter((o1: any) => !this.orgUsersList.some((o2: any) => o1.name.toLowerCase() === o2.name.toLowerCase()));
				let tenantdata = this.tenantsList.filter((tenant: any) => {
					return tenant._id == this.userInfo.organisationId;
				});
				let domainName = tenantdata[0].organizationName;
				let updatedUsers: any = [];
				for (var k = 0; k < users.length; k++) {
					if (users[k].email.substring(users[k].email.indexOf('@') + 1).split('.')[0] === domainName) {
						updatedUsers.push(users[k]);
					}
				}
				valid = updatedUsers.length;
				if (updatedUsers.length > 0) {
					await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/users/add`, updatedUsers).then(() => {
						toast.info(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
							timeout: 3000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.getallTeamMembers();
						this.newImportModal = !this.newImportModal;
					});
				} else {
					toast.error(`cannot save with same name`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			};
			reader.readAsArrayBuffer(oFile);
		},
		async getallTeams() {
			// console.log('JYOTHSNA');
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.departmentsList = res.data;
				for (var i = 0; i < res.data.length; i++) {
                    let a = {value:res.data[i]._id, label:res.data[i].teamName}
                    this.allTeamMembersList.push(a);
                }
			});
		},
		cancelBtn(selectedUser: any) {
			this.v$.$reset();
			if (this.selectedUser._id) {
				this.selectedUser = { ...this.editUserObj };
			} else {
				Object.keys(selectedUser).forEach((prop) => {
					if (prop == '_id') {
						delete selectedUser[prop];
					} else if (prop != 'roleMonitor' && prop != 'roleRisk' && prop != 'roleTraining' && prop != 'roleWorkflow' && prop != 'roleAudit' && prop != 'roleDMS') {
						Array.isArray(selectedUser[prop]) ? (selectedUser[prop] = []) : (selectedUser[prop] = '');
					}
				});
				this.selectedUser.isActive = 0
				let tenantdata = this.tenantsList.filter((tenant: any) => {
                   return tenant._id == this.userInfo.organisationId;
				});
				this.selectedUser.domainName = tenantdata[0].tenantName;
			}
		
		},
		async addOrUpdateUser(selectedUser: any) {
			console.log('SAve POpup',selectedUser);
			
			let finalUsers = this.searchList.filter((team: any) => team._id);
			let userExists = finalUsers.some((el: any) => {
				return el.name.toLowerCase() === selectedUser.name.toLowerCase();
			});
			this.v$.$touch();
			if (!this.v$.$invalid && (!this.checkEmailUniqueness(this.selectedUser.email))) {
				let selectedUserCopy:any = {...selectedUser}
				this.clickOnButton = true;
				let tempRoles = [
					{
						appId: 10001,
						roleId: selectedUser.roleMonitor
					},
					{
						appId: 10002,
						roleId: selectedUser.roleTraining
					},
					{
						appId: 10003,
						roleId: selectedUser.roleRisk
					},
					{
						appId: 10004,
						roleId: selectedUser.roleDMS
					},
					{
						appId: 10005,
						roleId: selectedUser.roleAudit
					},
					{
						appId: 10006,
						roleId: selectedUser.roleWorkflow
					}
				];
				
				selectedUserCopy.roles = tempRoles;
				selectedUserCopy.entity = selectedUser.entity.value
				selectedUserCopy.departmentId = selectedUser.departmentId.value
				console.log('selectedUserCopy',selectedUserCopy);
				

				let deletedKeys = _.omit(selectedUserCopy, ['uiroles', 'roleWorkflow', 'roleAudit', 'roleRisk', 'roleTraining', 'roleMonitor', 'domainName', 'roleDMS']);
				if (!selectedUserCopy._id && !userExists) {
					console.log("Hiiiiiii")
					deletedKeys.isActive = false;
                    try {
					await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/users/add`, [deletedKeys]).then(() => {
						this.showUserModal = false;
						this.getallTeamMembers();
						toast.info('saved', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						// this.sendInvite(selectedUser);
					});
					}
					catch (e){
                       toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
					}
					finally {
                    this.clickOnButton = false;
                }
				} else {
					this.updateUser(selectedUserCopy);
				}
			} else {
				// this.v$.$reset();
				if (selectedUser._id) {
					// toast.error(`Cannot Update 11`, {
					// 	timeout: 5000,
					// 	closeOnClick: true,
					// 	closeButton: 'button',
					// 	icon: true
					// });
				} else {
					// toast.error(`Cannot save 11`, {
					// 	timeout: 5000,
					// 	closeOnClick: true,
					// 	closeButton: 'button',
					// 	icon: true
					// });
				}
			}
		},
		async updateUser(selectedUserCopy: any) {
			if (selectedUserCopy._id) {
				try {
				await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/users/update`, _.omit(selectedUserCopy, ['uiroles', 'roleWorkflow', 'roleAudit', 'roleRisk', 'roleTraining', 'roleMonitor', 'domainName', 'roleDMS'])).then(() => {
					this.showUserModal = false;
					toast.info('Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.getallTeamMembers();
					this.getallTeams();
				});
			}
			catch (e){
			toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false;
                }
			}
			
		},
		async getAllEntities() {
			this.businessEntities = [];
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				res.data.map((obj: any) => {
					let a = { value: obj._id, label: obj.entityName };
					this.businessEntities.push(a);
				});
			});
		},
		async getAllTenants() {
			await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.tenantsList = res.data;
				console.log('TEN',this.tenantsList);
				
			});
		},
		async getallTeamMembers() {
			this.teamsDataArray = [],
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.originalTeamArray = res.data;
				this.orgUsersList = res.data;
				this.searchList = res.data;
				this.userEmails = [];
				this.originalTeamArray.forEach((name: any)=>{
                    if(name.email != null){
                       this.userEmails.push(name.email)
                    }
				})
                res.data.map((teamArray:any)=>{
					console.log('teamArray',teamArray);
					console.log('allTeamMembersList',this.allTeamMembersList);

					 var newDepartment = this.allTeamMembersList.find((team:any)=>{
						console.log('team',team);
						return team.value == teamArray.departmentId
					})
					teamArray.departmentId = newDepartment != undefined ? newDepartment : 'N/A';

				
					var newEntity = this.businessEntities.find((entityObj:any)=>{
						console.log('entityObj',entityObj);
						return entityObj.value == teamArray.entity
					})
					teamArray.entity = newEntity != undefined ? newEntity : 'N/A';

					console.log('newEntity',newEntity);
					let tableData = { 
						'name' : teamArray.name,
						'email' : teamArray.email,
						'team' : newDepartment != undefined ? newDepartment.label : 'N/A',
						'jobTitle' : teamArray.jobTitle,
						'status' : teamArray.isActive==true ? 1:2 ,
						_id : teamArray._id
					}
					console.log("tableData",tableData)
					this.teamsDataArray.push(tableData)
					console.log('FINAL',this.teamsDataArray);
					
				})
				// this.teamsDataArray = res.data;
				// console.log('teamsDataArray',this.teamsDataArray);
				
			});
		},
		checkEmailUniqueness(email: any) {
			if (this.teamsDataArray.length > 0 && email !== '' && email !== null) {
				const trimmedEmail = email.trim().toLowerCase(); // Use the full email for comparison
				let array: any = [];

				// Check if editing an email
				// if (this.selectedUser._id && this.teamsDataArray.length > 0) {
				// 	let originalObj = this.originalTeamArray.find((obj: any) => {
				// 		return obj._id === this.selectedEmailId;
				// 	});
				// // Filter out the original email from the array if it exists
				// 	if (originalObj && originalObj.email != null) {
				// 		array = this.userEmails.filter((obj: any) => {
				// 			return obj.toLowerCase() !== originalObj.email.toLowerCase();
				// 		});
						
				// 	} else {
				// 		array = this.userEmails; // If no original email, use all email addresses
						
				// 	}

				// 	// Compare the full email address (ignoring case)
				// 	const check = array.map((name: any) => name.toLowerCase()).includes(trimmedEmail);
				// 	return check;
				// }

				// Check for new email when adding one
				if ((!this.selectedUser._id) && this.teamsDataArray.length > 0) {
					array = this.userEmails; // Fill the array with all email addresses
					const check = array.map((name: any) => name.toLowerCase()).includes(trimmedEmail);
					return check;
				}
			}
       },

		toggleImportPopup() {
			// this.newImportModal = !this.newImportModal;
			this.$refs.file.click();
		},
		showEditPopup(newObj:any){
          this.showPopup(newObj, 'edit')
		},
	},
	async created() {
	    await this.getallTeams();
		await this.getAllEntities();
		await this.getAllTenants();
		await this.getallTeamMembers();
	},
	async mounted(){
		 emitter.off('dataTransfering')
        emitter.on('dataTransfering', (Data: any) => {
            console.log('Transfer Data',Data);
			this.selectedEmailId = Data._id;
            let onClickData = this.originalTeamArray.find((obj:any)=>{
				return obj._id == Data._id
			})
			console.log('Changing',onClickData);
			this.showEditPopup(onClickData)
        });
		 emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
		
	}

})
</script>