<template>
    <div class="fixed h-full bg-primary sidenav flex flex-col justify-between z-50" :class="{ 'w-40': isSidebarToggle, 'w-10': !isSidebarToggle }">
        <ul>
            <li @click="navigate('teams', 'teams')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'teams' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'"  @mouseenter="sidebar.teams = true" @mouseleave="sidebar.teams = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.50911 3.97988C2.78959 4.15823 2.17461 4.78243 2.00241 5.50195C1.94399 5.75409 1.94399 6.25837 2.00241 6.51052C2.17461 7.23004 2.80188 7.86347 3.50911 8.02951C3.6567 8.06641 3.67823 8.07871 3.61673 8.09101C3.02328 8.2263 2.84186 8.2878 2.54359 8.45385C1.85174 8.84743 1.34439 9.47778 1.10455 10.2557L1.02152 10.5263L1.00922 11.8393L1 13.1553H2.98023H4.96046L4.98198 12.8848C5.03733 12.1929 5.25872 11.5595 5.64923 10.9845C5.8491 10.6862 6.25806 10.2619 6.53787 10.0559C6.66394 9.96054 6.77157 9.88367 6.77772 9.87752C6.79924 9.86214 6.61475 9.53313 6.48253 9.34249C6.21194 8.9612 5.9229 8.69984 5.50779 8.45999C5.27102 8.32162 4.84976 8.16173 4.63144 8.13098C4.37008 8.08793 4.35778 8.07563 4.55457 8.02951C5.26487 7.86654 5.89215 7.23004 6.06434 6.49514C6.13506 6.1938 6.11047 5.6188 6.01822 5.34513C5.80913 4.73323 5.30484 4.22895 4.69294 4.01986C4.43158 3.93068 3.792 3.90916 3.50911 3.97988Z" fill="#E9E9E9"/>
                            <path d="M13.1953 3.97988C12.7741 4.08443 12.3221 4.38577 12.0607 4.73015C11.7686 5.12374 11.6425 5.50195 11.6425 6.00623C11.6425 6.51052 11.7686 6.88873 12.0607 7.28231C12.3251 7.62978 12.7864 7.93726 13.1953 8.02951C13.3921 8.07563 13.3798 8.08793 13.1185 8.13098C12.9001 8.16173 12.4789 8.32162 12.2421 8.45999C11.827 8.69984 11.538 8.9612 11.2674 9.34249C11.1352 9.53313 10.9507 9.86214 10.9722 9.87752C10.9783 9.88367 11.086 9.96054 11.212 10.0559C11.4918 10.2619 11.9008 10.6862 12.1007 10.9845C12.4912 11.5595 12.7126 12.1929 12.7679 12.8848L12.7894 13.1553H14.7697H16.7499L16.7407 11.8393L16.7284 10.5263L16.6454 10.2557C16.4055 9.47778 15.8982 8.84743 15.2063 8.45385C14.908 8.2878 14.7266 8.2263 14.1332 8.09101C14.0717 8.07871 14.0932 8.06641 14.2408 8.02951C14.9572 7.86347 15.5784 7.23004 15.7506 6.49514C15.8213 6.1938 15.7967 5.6188 15.7044 5.34513C15.4953 4.73323 14.9911 4.22895 14.3792 4.01986C14.1178 3.93068 13.4782 3.90916 13.1953 3.97988Z" fill="#E9E9E9"/>
                            <path d="M8.42898 6.03979C8.15839 6.09821 7.77403 6.28271 7.54649 6.4672C7.09141 6.83311 6.78392 7.48499 6.78392 8.08152C6.78392 8.67804 7.09141 9.32992 7.54649 9.69583C7.76173 9.86803 8.15224 10.0617 8.39516 10.114L8.57658 10.154L8.34904 10.1909C7.2882 10.3723 6.39033 11.1349 6.03057 12.165C5.88605 12.5801 5.86145 12.8322 5.86145 13.9576V15H8.87484H11.8882V13.9576C11.8882 12.8322 11.8636 12.5801 11.7191 12.165C11.3594 11.1349 10.4615 10.3723 9.40065 10.1909L9.17311 10.154L9.35452 10.114C9.59744 10.0617 9.98795 9.86803 10.2032 9.69583C10.6583 9.32992 10.9658 8.67804 10.9658 8.08152C10.9658 7.64796 10.7997 7.16212 10.523 6.79929C10.0556 6.18123 9.21308 5.87682 8.42898 6.03979Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName == 'teams' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Teams</div>
                    </a>
                </div>
                <div v-if="sidebar.teams && !isSidebarToggle" class="absolute z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'teams' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Teams</div>
                </div>
            </li>
            <li @click="navigate('users', 'users')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'users' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'"  @mouseenter="sidebar.users = true" @mouseleave="sidebar.users = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.49134 1.32604C8.018 1.42824 7.34564 1.75097 6.94761 2.0737C6.15154 2.71379 5.61365 3.8541 5.61365 4.8976C5.61365 5.94109 6.15154 7.08141 6.94761 7.72149C7.32413 8.02271 8.00724 8.36157 8.43217 8.45301L8.74952 8.52294L8.35149 8.58748C6.49578 8.90484 4.92516 10.2388 4.29584 12.0407C4.04303 12.7668 4 13.2079 4 15.1766V17H9.27127H14.5425V15.1766C14.5425 13.2079 14.4995 12.7668 14.2467 12.0407C13.6174 10.2388 12.0468 8.90484 10.1911 8.58748L9.79302 8.52294L10.1104 8.45301C10.5353 8.36157 11.2184 8.02271 11.5949 7.72149C12.391 7.08141 12.9289 5.94109 12.9289 4.8976C12.9289 4.13918 12.6384 3.28932 12.1543 2.65462C11.3367 1.57347 9.86294 1.04096 8.49134 1.32604Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName === 'users' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Users</div>
                    </a>
                </div>
                <div v-if="sidebar.users && !isSidebarToggle" class="absolute z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'users' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Users</div>
                </div>
            </li>
            <li @click="navigate('regions', 'regions')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'regions' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'"  @mouseenter="sidebar.regions = true" @mouseleave="sidebar.regions = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.49134 1.32604C8.018 1.42824 7.34564 1.75097 6.94761 2.0737C6.15154 2.71379 5.61365 3.8541 5.61365 4.8976C5.61365 5.94109 6.15154 7.08141 6.94761 7.72149C7.32413 8.02271 8.00724 8.36157 8.43217 8.45301L8.74952 8.52294L8.35149 8.58748C6.49578 8.90484 4.92516 10.2388 4.29584 12.0407C4.04303 12.7668 4 13.2079 4 15.1766V17H9.27127H14.5425V15.1766C14.5425 13.2079 14.4995 12.7668 14.2467 12.0407C13.6174 10.2388 12.0468 8.90484 10.1911 8.58748L9.79302 8.52294L10.1104 8.45301C10.5353 8.36157 11.2184 8.02271 11.5949 7.72149C12.391 7.08141 12.9289 5.94109 12.9289 4.8976C12.9289 4.13918 12.6384 3.28932 12.1543 2.65462C11.3367 1.57347 9.86294 1.04096 8.49134 1.32604Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName === 'regions' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Regions</div>
                    </a>
                </div>
                <div v-if="sidebar.regions && !isSidebarToggle" class="absolute rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'regions' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Regions</div>
                </div>
            </li>
            <li @click="navigate('businessEntites', 'businessEntites')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'businessEntites' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'" @mouseenter="sidebar.businessEntites = true" @mouseleave="sidebar.businessEntites = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.49134 1.32604C8.018 1.42824 7.34564 1.75097 6.94761 2.0737C6.15154 2.71379 5.61365 3.8541 5.61365 4.8976C5.61365 5.94109 6.15154 7.08141 6.94761 7.72149C7.32413 8.02271 8.00724 8.36157 8.43217 8.45301L8.74952 8.52294L8.35149 8.58748C6.49578 8.90484 4.92516 10.2388 4.29584 12.0407C4.04303 12.7668 4 13.2079 4 15.1766V17H9.27127H14.5425V15.1766C14.5425 13.2079 14.4995 12.7668 14.2467 12.0407C13.6174 10.2388 12.0468 8.90484 10.1911 8.58748L9.79302 8.52294L10.1104 8.45301C10.5353 8.36157 11.2184 8.02271 11.5949 7.72149C12.391 7.08141 12.9289 5.94109 12.9289 4.8976C12.9289 4.13918 12.6384 3.28932 12.1543 2.65462C11.3367 1.57347 9.86294 1.04096 8.49134 1.32604Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName === 'businessEntites' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Business Entites</div>
                    </a>
                </div>
                <div v-if="sidebar.businessEntites && !isSidebarToggle" class="absolute  z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'businessEntites' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Business Entites</div>
                </div>
            </li>
            <li
                @click="navigate('vendors', 'vendors')"
                @mouseenter="sidebar.vendors = true"
                @mouseleave="sidebar.vendors = false"
                class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5"
                :class="selectedOptionName === 'vendors' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'"
            >
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2328_26181)">
                                <path
                                    d="M5.02793 2.80666L1.13449 4.48927L1.12968 5.06084L1.12646 5.63402H1.67074H2.21342L2.22305 5.70627C2.24553 5.87003 2.37879 6.04985 2.53613 6.12692L2.62765 6.17188H8.96954H15.3114L15.3869 6.13655C15.5539 6.05949 15.6968 5.87646 15.716 5.71269L15.7257 5.63402H16.2539H16.7805L16.7773 5.06245L16.7725 4.49087L12.8646 2.80666C10.7164 1.88026 8.94867 1.12245 8.93903 1.12405C8.9294 1.12405 7.16972 1.88187 5.02793 2.80666Z"
                                    fill="#E9E9E9"
                                />
                                <path
                                    d="M3.19769 10.1138V13.4822L2.89746 13.4902C2.61006 13.4967 2.5908 13.4999 2.49768 13.5416C2.37887 13.5946 2.25042 13.7198 2.19905 13.8322C2.16533 13.9061 2.16212 13.9382 2.1573 14.2689L2.15088 14.627H8.95998H15.7691V14.2818C15.7691 13.9767 15.7659 13.9285 15.737 13.8515C15.6904 13.7262 15.57 13.5994 15.4448 13.5432C15.3484 13.4999 15.3308 13.4967 15.0434 13.4902L14.7415 13.4822V10.1138V6.74374H13.6899H12.6383V10.1154V13.487H12.1245H11.6107V10.1154V6.74374H10.5591H9.50747V10.1154V13.487H8.9937H8.47992V10.1154V6.74374H7.42829H6.37667V10.1154V13.487H5.86289H5.34912V10.1154V6.74374H4.27341H3.19769V10.1138Z"
                                    fill="#E9E9E9"
                                />
                                <path
                                    d="M1.51675 15.2197C1.36262 15.2727 1.24542 15.3754 1.17156 15.5263C1.13463 15.597 1.13463 15.6114 1.12982 16.236L1.125 16.875H8.95362H16.7838L16.7774 16.252L16.7726 15.6307L16.7277 15.5328C16.6731 15.414 16.5462 15.2855 16.4354 15.2341L16.3552 15.1972L8.97771 15.194C1.91172 15.1908 1.59703 15.1908 1.51675 15.2197Z"
                                    fill="#E9E9E9"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2328_26181">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName === 'vendors' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Vendors</div>
                    </a>
                </div>
                <div v-if="sidebar.vendors && !isSidebarToggle" class="absolute  z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'vendors' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Vendors</div>
                </div>
            </li>
            <li
                @click="navigate('bussinessProcess', 'bussinessProcess')"
                @mouseenter="sidebar.bussinessProcess = true"
                @mouseleave="sidebar.bussinessProcess = false"
                class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5"
                :class="selectedOptionName === 'bussinessProcess' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'"
            >
            <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2328_26181)">
                                <path
                                    d="M5.02793 2.80666L1.13449 4.48927L1.12968 5.06084L1.12646 5.63402H1.67074H2.21342L2.22305 5.70627C2.24553 5.87003 2.37879 6.04985 2.53613 6.12692L2.62765 6.17188H8.96954H15.3114L15.3869 6.13655C15.5539 6.05949 15.6968 5.87646 15.716 5.71269L15.7257 5.63402H16.2539H16.7805L16.7773 5.06245L16.7725 4.49087L12.8646 2.80666C10.7164 1.88026 8.94867 1.12245 8.93903 1.12405C8.9294 1.12405 7.16972 1.88187 5.02793 2.80666Z"
                                    fill="#E9E9E9"
                                />
                                <path
                                    d="M3.19769 10.1138V13.4822L2.89746 13.4902C2.61006 13.4967 2.5908 13.4999 2.49768 13.5416C2.37887 13.5946 2.25042 13.7198 2.19905 13.8322C2.16533 13.9061 2.16212 13.9382 2.1573 14.2689L2.15088 14.627H8.95998H15.7691V14.2818C15.7691 13.9767 15.7659 13.9285 15.737 13.8515C15.6904 13.7262 15.57 13.5994 15.4448 13.5432C15.3484 13.4999 15.3308 13.4967 15.0434 13.4902L14.7415 13.4822V10.1138V6.74374H13.6899H12.6383V10.1154V13.487H12.1245H11.6107V10.1154V6.74374H10.5591H9.50747V10.1154V13.487H8.9937H8.47992V10.1154V6.74374H7.42829H6.37667V10.1154V13.487H5.86289H5.34912V10.1154V6.74374H4.27341H3.19769V10.1138Z"
                                    fill="#E9E9E9"
                                />
                                <path
                                    d="M1.51675 15.2197C1.36262 15.2727 1.24542 15.3754 1.17156 15.5263C1.13463 15.597 1.13463 15.6114 1.12982 16.236L1.125 16.875H8.95362H16.7838L16.7774 16.252L16.7726 15.6307L16.7277 15.5328C16.6731 15.414 16.5462 15.2855 16.4354 15.2341L16.3552 15.1972L8.97771 15.194C1.91172 15.1908 1.59703 15.1908 1.51675 15.2197Z"
                                    fill="#E9E9E9"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2328_26181">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName === 'bussinessProcess' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Business Process</div>
                    </a>
                </div>
                <div v-if="sidebar.bussinessProcess && !isSidebarToggle" class="absolute  z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'bussinessProcess' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Business Process</div>
                </div>
            </li>
            <li
                @click="navigate('systems', 'systems')"
                @mouseenter="sidebar.systems = true"
                @mouseleave="sidebar.systems = false"
                class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5"
                :class="selectedOptionName === 'systems' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'"
            >
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.19329 6.70056C9.19329 6.58592 9.12349 6.45254 9.03751 6.4029L4.25965 3.6444C4.11637 3.56165 4 3.64964 4 3.84069V13.7272C4 13.88 4.09309 14.0579 4.20773 14.1241L8.46623 16.5827C8.86751 16.8144 9.19329 16.5681 9.19329 16.0331V6.70056Z" fill="#E9E9E9" />
                            <path
                                d="M13.8513 3.93405C13.8513 3.86311 13.8081 3.83044 13.7549 3.86116L9.12719 6.53299C9.07399 6.56371 9.03076 6.64624 9.03076 6.71718V16.2166C9.03076 16.6068 9.26838 16.7864 9.56102 16.6174L13.7549 14.1961C13.8081 14.1654 13.8513 14.0828 13.8513 14.0119V3.93405Z"
                                fill="#E9E9E9"
                            />
                            <path
                                d="M9.3402 1.18313L9.36517 1.13989L9.34021 1.18313L13.5835 3.63296C13.7227 3.71332 13.7891 3.80825 13.7992 3.89004C13.8089 3.96793 13.7694 4.05149 13.6545 4.11778L9.47608 6.53022L9.50108 6.57352L9.47608 6.53022C9.36004 6.59723 9.19605 6.62851 9.02297 6.62133C8.85038 6.61417 8.67449 6.56897 8.53632 6.48919L8.51132 6.53249L8.53632 6.48919L4.29308 4.03936C4.15388 3.959 4.08748 3.86406 4.07731 3.78228C4.06762 3.70438 4.10718 3.62083 4.22199 3.55453L8.40045 1.1421C8.51649 1.07511 8.68048 1.04383 8.85356 1.051C9.02615 1.05816 9.20204 1.10335 9.3402 1.18313Z"
                                fill="#E9E9E9"
                                stroke="#152A43"
                                stroke-width="0.1"
                            />
                            <path
                                d="M9.36521 11.218C9.07249 11.0489 8.62897 11.0305 8.37545 11.1769L4.19699 13.5894C3.9435 13.7357 3.97533 13.9918 4.26808 14.1608L8.51132 16.6106C8.80404 16.7797 9.24755 16.7981 9.50108 16.6517L13.6795 14.2392C13.9331 14.0929 13.9012 13.8368 13.6085 13.6678L9.36521 11.218Z"
                                fill="#E9E9E9"
                            />
                            <path class="systems" d="M8.95021 9.21027L4.02588 6.36719V6.92799L8.95021 9.77103V9.21027ZM13.6078 6.52117L8.95021 9.21027V9.77103L13.6078 7.08197V6.52117Z" fill="#152A43" />
                            <path
                                class="systems"
                                d="M8.04777 11.1471C8.04777 11.1141 8.02775 11.0759 8.00308 11.0616L4.75615 9.18701C4.73148 9.17275 4.71143 9.18789 4.71143 9.22081V10.2939C4.71143 10.3268 4.73148 10.3651 4.75615 10.3794L8.00308 12.254C8.02775 12.2682 8.04777 12.2531 8.04777 12.2202V11.1471ZM8.04777 13.459C8.04777 13.4261 8.02775 13.3879 8.00308 13.3736L4.75615 11.499C4.73148 11.4848 4.71143 11.4999 4.71143 11.5328V12.6059C4.71143 12.6388 4.73148 12.6771 4.75615 12.6913L8.00308 14.566C8.02775 14.5802 8.04777 14.5651 8.04777 14.5322V13.459Z"
                                fill="#152A43"
                            />
                            <path
                                d="M7.82789 6.47074C7.94932 6.54081 8.04787 6.76141 8.04787 6.96306C8.04787 7.16467 7.94932 7.27147 7.82789 7.20137C7.7065 7.13127 7.60791 6.91066 7.60791 6.70905C7.60791 6.50741 7.7065 6.40064 7.82789 6.47074ZM7.82789 7.49731C7.94932 7.56737 8.04787 7.78798 8.04787 7.98962C8.04787 8.19123 7.94932 8.29803 7.82789 8.22793C7.7065 8.15783 7.60791 7.93723 7.60791 7.73562C7.60791 7.53397 7.7065 7.42721 7.82789 7.49731Z"
                                fill="#EDEEF0"
                            />
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName === 'systems' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Systems</div>
                    </a>
                </div>
                <div v-if="sidebar.systems && !isSidebarToggle" class="absolute  z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'systems' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Systems</div>
                </div>
            </li>
            <li
                @click="navigate('labelDefinitions', 'labelDefinitions')"
                @mouseenter="sidebar.labelDefinitions = true"
                @mouseleave="sidebar.labelDefinitions = false"
                class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5"
                :class="selectedOptionName === 'labelDefinitions' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'"
            >
               <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7041_46449)">
                                <rect y="5.72656" width="4.90909" height="6.54545" rx="0.5" fill="#E9E9E9" />
                                <rect x="12.6362" width="4.90909" height="3.27273" rx="0.5" fill="#E9E9E9" />
                                <rect x="12.6362" y="7.36328" width="4.90909" height="3.27273" rx="0.5" fill="#E9E9E9" />
                                <rect x="12.6362" y="14.7266" width="4.90909" height="3.27273" rx="0.5" fill="#E9E9E9" />
                                <path d="M12 1.63623H8.54541C7.99313 1.63623 7.54541 2.08395 7.54541 2.63623V15.3635C7.54541 15.9158 7.99313 16.3635 8.54541 16.3635H12" stroke="#E9E9E9" />
                                <path d="M5.5 9H12" stroke="#E9E9E9" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7041_46449">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
 
                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName === 'labelDefinitions' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Label Definitions</div>
                    </a>
                </div>
                <div v-if="sidebar.labelDefinitions && !isSidebarToggle" class="absolute  z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'labelDefinitions' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Label Definitions</div>
                </div>
            </li>
        </ul>
            <ul class="bottomUl mb-16">
            <li class="relative">
                <hr class="mb-4" />
                <img src="@/assets/leftarrow.svg" class=" icon   arrowIcon leftarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-if="isSidebarToggle" />
                <img src="@/assets/rightarrow.svg" class="arrowiconRight  icon  rightarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-else />
            </li>
           
                </ul>
 
        <div class="fixed z-50 bg-white w-40 px-2 py-1 flex flex-col justify-center" v-if="menuVisible">
            <div class="flex flex-row items-center cursor-pointer">
                <!-- <div class="flex flex-col mr-1 w-2/4 items-center" @click="triggerNav('monitor')">
                    <img src="@/assets/monitoring.svg" alt="" class="icn h-6 w-6 ml-2" />
                    <div class="nav-item-label-container">
                        <div class="nav-item-label">Monitor</div>
                    </div>
                </div> -->
                <div class="flex flex-row items-center cursor-pointer" @click="triggerNav('training')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4516_14738)">
                    <path d="M0 0C7.92 0 15.84 0 24 0C24 7.92 24 15.84 24 24C16.08 24 8.16 24 0 24C0 16.08 0 8.16 0 0Z" fill="#FF9A00"/>
                    <path d="M19.6399 17.0902V8.18107C19.6399 7.67475 19.4388 7.18916 19.0808 6.83114C18.7227 6.47311 18.2371 6.27198 17.7308 6.27198H17.0945C17.0944 5.97653 17.0258 5.68513 16.894 5.42071C16.7622 5.15629 16.5708 4.92607 16.3349 4.74817C16.099 4.57027 15.8251 4.44954 15.5346 4.3955C15.2441 4.34145 14.9451 4.35556 14.661 4.43671L12.7519 4.98271C12.4741 5.06217 12.2181 5.20408 12.0036 5.39762C11.789 5.20408 11.533 5.06217 11.2552 4.98271L9.3461 4.43671C9.06201 4.35556 8.76297 4.34145 8.4725 4.3955C8.18204 4.44954 7.90809 4.57027 7.6722 4.74817C7.43632 4.92607 7.24494 5.15629 7.11314 5.42071C6.98134 5.68513 6.9127 5.97653 6.91264 6.27198H6.27628C5.76996 6.27198 5.28437 6.47311 4.92635 6.83114C4.56832 7.18916 4.36719 7.67475 4.36719 8.18107V17.0902H11.3672V18.3629H8.82173V19.6356H15.1854V18.3629H12.6399V17.0902H19.6399ZM12.6399 6.81798C12.64 6.67977 12.685 6.54534 12.7683 6.43501C12.8515 6.32467 12.9684 6.24444 13.1013 6.20643L15.0104 5.66107C15.0673 5.6446 15.1261 5.63604 15.1854 5.63562C15.3541 5.63562 15.516 5.70266 15.6353 5.822C15.7547 5.94134 15.8217 6.1032 15.8217 6.27198V10.2467L12.6399 11.1554V6.81798ZM8.18537 6.27198C8.18551 6.17354 8.20849 6.07648 8.25249 5.98843C8.29649 5.90038 8.36032 5.82373 8.43896 5.76453C8.51759 5.70532 8.6089 5.66516 8.70568 5.64721C8.80246 5.62925 8.90209 5.634 8.99673 5.66107L10.9058 6.20643C11.0387 6.24444 11.1556 6.32467 11.2388 6.43501C11.3221 6.54534 11.3671 6.67977 11.3672 6.81798V11.1554L8.18537 10.2467V6.27198ZM5.63991 8.18107C5.63991 8.0123 5.70696 7.85043 5.8263 7.73109C5.94564 7.61175 6.1075 7.54471 6.27628 7.54471H6.91264V11.2063L12.0036 12.6611L17.0945 11.2063V7.54471H17.7308C17.8996 7.54471 18.0615 7.61175 18.1808 7.73109C18.3001 7.85043 18.3672 8.0123 18.3672 8.18107V15.8174H5.63991V8.18107Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_4516_14738">
                    <rect width="24" height="24" rx="4" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    <div class="nav-item-label-container ml-2">
                        <div class="text-sm font-semibold">Training</div>
                        <div class="active-border"></div>
                    </div>
                </div>
                <!-- <div class="flex flex-col w-2/4 items-center cursor-pointer" @click="triggerNav('workflow')">
                    <img src="@/assets/monitoring.svg" alt="" class="icn h-6 w-6 ml-2" />
                    <div class="nav-item-label-container">
                        <div class="nav-item-label active">Workflow</div>
                        <div class="active-border"></div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<style scoped >
.min-h-30{
    height: 50px;
}
.w-40{
    width: 170px;
}
.w-3{
    width:15px
}
.shadow {
    --tw-shadow:5px 5px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.leftarrow ,
.rightarrow{
    /* height: 1.25rem;
    width: 1.25rem; */
    margin-top: -27px;
}
.arrowIcon{
    height:22px;
    width:22px;
    margin-left:157px;
}
.arrowiconRight{
    height:22px;
    width:22px;
    margin-left:29px;
}
.a:hover {
    color: black;
    font-weight: 400;
}
.svg-icon:hover {
    color:black;
}
.svg-icon123 div a svg path {
    fill: #152A43;
}
.svg-icon:hover path {
    fill: black; /* Change the color to your desired hover color */
}
.svg-Image:hover svg path{
    fill: #152A43;
}
.icon:hover {
transition: none !important;
}
.tooltipwidth {
    width: auto;
	z-index : 200 !important
}
.bg-tooltip{
    background-color: #152a43;
    border-radius: 3px;
}
.transform-y-top{
    transform: translateY(-28px);
}
.svg-icon123 div a svg path.systems,
.svg-icon:hover path.systems {
    fill: #e9e9e9;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
    data():any {
        return {
            isSidebarToggle: true,
            appsVisible:false,
            menuVisible: false,
            selectedOptionName: '',
            passingRouteName: '',
            sidebar: { teams: false, users: false, regions: false, businessEntites: false , vendors: false , bussinessProcess: false , systems : false , labelDefinitions: false},
            a:0,
                menus: [
                {
                    optionName: 'teams',
                    optionRoute: 'teams',
                    defaultoptionImage: require('@/assets/teamsDefault.svg'),
                    colouredoptionImage: require('@/assets/teamsColoured.svg'),
                    displayOptionName: 'Teams'
                },
                {
                    optionName: 'users',
                    defaultoptionImage: require('@/assets/usersDefault.svg'),
                    colouredoptionImage: require('@/assets/usersColoured.svg'),
                    optionRoute: 'users',
                    displayOptionName: 'Users',
                },
                {
                    optionName: 'labelDefinitions',
                    optionRoute: 'labelDefinitions',
                    defaultoptionImage: require('../assets/Info_Classification.svg'),
                    colouredoptionImage: require('../assets/Info_Classification_Selected.svg'),
                    displayOptionName: 'labelDefinitions',
                    id: 20005
                },
                {
                    optionName: 'systems',
                    optionRoute: 'systems',
                    defaultoptionImage: require('../assets/Systems.svg'),
                    colouredoptionImage: require('../assets/SystemsColored.svg'),
                    displayOptionName: 'Systems',
                    id: 20005
                },
            ]
        };
    },
       
    methods:{
        navigate(name:any, routerOption:any) {
            this.$store.dispatch('selectedToggle',this.isSidebarToggle);
            this.$store.dispatch('selectedRoute', name);
            this.$router.push({ name: routerOption });
        },
        toggleSideMenuBar() {
            this.isSidebarToggle = !this.isSidebarToggle;
            emitter.emit('toggle-sidebar', this.isSidebarToggle);
        },
        triggerNav(app:string) {
            if(app=="workflow"){
                window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_WORKFLOW_WEBAPP_URL}`;
            }else{
                window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${app == 'monitor' ? process.env.VUE_APP_MONITOR_WEBAPP_URL : process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
            }
           
        },
         debouncedHandleResize() {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(this.handleResize, 100); // Adjust debounce time as needed
        },
        handleResize() {
            if(window.innerWidth<1164)
            {
                this.$store.dispatch('selectedToggle', false);
                this.isSidebarToggle = false;
                emitter.emit('toggle-sidebar', this.isSidebarToggle);
                // console.log("hii checking reponsive or not");
            }
            // else{
            //  this.$store.dispatch('selectedToggle', true);
            //  this.isSidebarToggle = true;
            //  emitter.emit('toggle-sidebar', this.isSidebarToggle);
            //  // console.log("big screen");
            // }
        },
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        ...mapGetters({ userInfo: 'userInfo',toggleState:'toggle'})
    },
    created(){
        this.handleResize()
    },
    mounted() {
        window.addEventListener('resize', this.debouncedHandleResize);
        if(this.$route.path.includes('teams')){
            this.passingRouteName = 'teams';
        }else if(this.$route.path.includes('users')){
            this.passingRouteName = 'users'
        }else if(this.$route.path.includes('regions') || Object.keys(this.$route.params).includes('regionId') || this.$route.path.includes('editEntityAtRegions') ||Object.keys(this.$route.query).includes('addAtRegions')){
            this.passingRouteName = 'regions'
        }else if(this.$route.path.includes('businessEntites') || this.$route.path.includes('addEntity') || this.$route.path.includes('editEntity')){
            this.passingRouteName = 'businessEntites'
        }else if(this.$route.path.includes('vendors')){
            this.passingRouteName = 'vendors'
        }else if(this.$route.path.includes('bussinessProcess')){
            this.passingRouteName = 'bussinessProcess'
        }else if(this.$route.path.includes('labelDefinitions')){
            this.passingRouteName = 'labelDefinitions'
        }else if(this.$route.path.includes('systems')){
            this.passingRouteName = 'systems'
        }
        // console.log(this.passingRouteName, 'this.passingRouteName');
        this.selectedOptionName = this.passingRouteName;
        // console.log('selectedOptionName', this.selectedOptionName);
            emitter.on('apps-sidemenubar', (isMenuToggle) => {
            this.menuVisible = isMenuToggle;
        });
        emitter.on('apps', (isToggle: any) => {
            this.menuVisible = isToggle;
        });
        this.isSidebarToggle=this.toggleState;
        emitter.on('toggle-sidebar', (isToggle: any) => {
        this.isSidebarToggle = isToggle;
        });
    },
     beforeUnmount() {
        window.removeEventListener('resize', this.debouncedHandleResize);
    },
});
</script>