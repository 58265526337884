<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="flex justify-between items-center">
            <h1 class="title">Business Process</h1>
            <div class="flex items-center">
                <div class="noteam text-center">
                    <button class="btnprimary mr-2" @click="showPopUp()">Add</button>
                </div>
                <div>
                    <div>
                        <button @click="filterOpen" class="flex" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 fontSizeInPage">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>

        <newTable :tableRowData="tableRow" :headersData="headers" :openPopup="false" :navigate="true"></newTable>
          <div v-if="toolTipActive">
            <tool-tip :tipText="toolTipText"></tool-tip>
        </div>

        <popup v-if="popUpOpen" :popUp="popUpOpen">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Business Process</h1>
                    <button class="float-right text-3xl" @click="onClosebtn()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Department</label>
                                        <multiselect 
                                            v-model="businessProcess.department" 
                                            :options="teamOptions"
											@search-change="asyncFindForTeams"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Department"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                    'is-invalid borderRed': v$.businessProcess.department.$error,
                                                }"
                                        >
                                        </multiselect>
                                        <div v-if="v$.businessProcess.department.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.department.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                 <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Description</label>
                                        <textarea
                                            type="text"
                                            v-model.trim="businessProcess.description"
                                            placeholder="Description"
                                            class="inputboxstyling"
                                            rows="3"
                                            :class="{
                                                'is-invalid': v$.businessProcess.description.$error,
                                            }"
                                        />
                                        <div v-if="v$.businessProcess.description.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.description.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                 </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class=" w-6/12">
                                        <label class="controllabel">Business Process Name</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="businessProcess.businessProcessName"
                                            placeholder="Business Process Name"
                                            :class="{
                                                'is-invalid': v$.businessProcess.businessProcessName.$error
                                            }"
                                        />
                                        <div v-if="v$.businessProcess.businessProcessName.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.businessProcessName.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="leftspacing w-6/12">
                                        <label class="controllabel">Criticality</label>
                                        <select
                                            v-model="businessProcess.criticality"
                                            :class="{
                                                'is-invalid': v$.businessProcess.criticality.$error,'inputboxstylingForSelectAtPopup': businessProcess.criticality === '','stylingForInputboxstylingForSelectAtPopup': businessProcess.criticality !== ''
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option class="optionColors" v-for="obj in dbProcessCriticality" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
                                        <!-- <multiselect 
                                            v-model="businessProcess.criticality" 
                                            :options="dbProcessCriticality"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="key"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Criticality"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                    'is-invalid borderRed': v$.businessProcess.criticality.$error,
                                                }"
                                        > -->
                                        <!-- </multiselect> -->
                                        <div v-if="v$.businessProcess.criticality.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.criticality.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">System(s)</label>

                                        <multiselect 
                                            v-model="businessProcess.systems" 
                                            :options="systemOptions"
											@search-change="asyncFindSystems"
                                            :showNoOptions="false"
                                            :multiple="true"
                                            :hide-selected="true"
                                            :close-on-select="false"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Systems"
                                            class="custom-multiselect userSelect"
                                            mode="tags"
                                        >
                                        </multiselect>
                                        <!-- :class="{
                                                    'is-invalid borderRed': v$.businessProcess.systems.$error,
                                                }" -->
                                        <!-- <div v-if="v$.businessProcess.systems.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.systems.required.$invalid">Required</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel ">Business Owner</label>
                                        <div>
                                            <multiselect 
                                            v-model="businessProcess.name" 
                                            :options="userOptions"
											@search-change="asyncFind"
                                            @select="selectedBusinessOwner"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Business Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                    'is-invalid borderRed': v$.businessProcess.name.$error,
                                                }"
                                        >
                                        </multiselect>
                                            <div v-if="v$.businessProcess.name.$error" class="text-red-500">
                                                <div class="error-text" v-if="v$.businessProcess.name.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Job Title</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="businessProcess.role"
                                            placeholder="Job Title"
                                            :disabled="true"
                                            :class="{
                                                'is-invalid': v$.businessProcess.role.$error
                                            }"
                                        />
                                        <div v-if="v$.businessProcess.role.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.role.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                        <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(businessProcess._id)">Cancel</button>
                        <button class="btnprimary" :disabled="clickOnButton" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" v-if="!businessProcess._id" @click="addOrUpdate(businessProcess)">Save</button>
                        <!-- <button class="btnprimary" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" v-else @click="addOrUpdate(businessProcess)">Update</button> -->
                    </div>
                </div>
            </fieldset>
        </popup>
        <popup v-if="editpop" :popUp="popUpOpen">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Business Process</h1>
                    <button class="float-right text-3xl" @click="onClosebtn()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class=" w-6/12">
                                        <label class="controllabel">Business Process Name</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="businessProcess.businessProcessName"
                                            placeholder="Business Process Name"
                                            :class="{
                                                'is-invalid': v$.businessProcess.businessProcessName.$error
                                            }"
                                        />
                                        <div v-if="v$.businessProcess.businessProcessName.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.businessProcessName.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel ">Business Owner</label>
                                        <div>
                                        <multiselect 
                                            v-model="businessProcess.name" 
                                            :options="userOptions"
											@search-change="asyncFind"
                                             @select="selectedBusinessOwner"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Business Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                    'is-invalid borderRed': v$.businessProcess.name.$error,
                                                }"
                                        >
                                        </multiselect>
                                            <div v-if="v$.businessProcess.name.$error" class="text-red-500">
                                                <div class="error-text" v-if="v$.businessProcess.name.required.$invalid">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Department</label>
                                        <multiselect 
                                            v-model="businessProcess.department" 
                                            :options="teamOptions"
											@search-change="asyncFindForTeams"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Department"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                    'is-invalid borderRed': v$.businessProcess.department.$error,
                                                }"
                                        >
                                        </multiselect>
                                        <div v-if="v$.businessProcess.department.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.department.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Job Title</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="businessProcess.role"
                                            :disabled="true"
                                            placeholder="Job Title"
                                            :class="{
                                                'is-invalid': v$.businessProcess.role.$error
                                            }"
                                        />
                                        <div v-if="v$.businessProcess.role.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.role.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                 <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Description</label>
                                        <textarea
                                            type="text"
                                            rows="3"
                                            v-model.trim="businessProcess.description"
                                            placeholder="Description"
                                            class="inputboxstyling"
                                            :class="{
                                                'is-invalid': v$.businessProcess.description.$error,
                                            }"
                                        />
                                        <div v-if="v$.businessProcess.description.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.description.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                 </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Criticality</label>
                                        <select
                                            class="inputboxstyling"
                                            v-model="businessProcess.criticality"
                                            :class="{
                                                'is-invalid': v$.businessProcess.criticality.$error
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option v-for="obj in dbProcessCriticality" :key="obj" :value="obj.value">{{ obj.key }}</option>
                                        </select>
                                        <!-- <multiselect 
                                            v-model="businessProcess.criticality" 
                                            :options="dbProcessCriticality"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="key"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Criticality"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                    'is-invalid borderRed': v$.businessProcess.criticality.$error,
                                                }"
                                        >
                                        </multiselect> -->
                                        <div v-if="v$.businessProcess.criticality.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.criticality.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">System(s)</label>
                                        <!-- <div v-if="v$.businessProcess.systems.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.systems.required.$invalid">Required</div>
                                        </div> -->
                                        <multiselect 
                                            v-model="businessProcess.systems" 
                                            :options="systemOptions"
											@search-change="asyncFindSystems"
                                            :showNoOptions="false"
                                            :multiple="true"
                                            :hide-selected="true"
                                            :close-on-select="false"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Systems"
                                            class="custom-multiselect userSelect"
                                        >
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="buttonposition flex justify-center items-center mb-4 mx-0">
                        <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(businessProcess._id)">Cancel</button>
                        <!-- <button class="btnprimary" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" v-if="!businessProcess._id" @click="addOrUpdate(businessProcess)">Save</button> -->
                        <button class="btnprimary"  :disabled="clickOnButton" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(businessProcess)">Update</button>
                    </div>
                </div>
            </fieldset>
        </popup>
    </div>
</template>
<style scoped>
.inputboxstylingForSelectAtPopup .optionColors{
   color : #4D4D4D !important;
}
.invalid-multiselect {
    border: 1px solid red !important;
}
.v-select-list li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { required, maxLength, minLength, email, helpers, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '@/main';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import popup from '@/components/popup.vue';
// import Multiselect from '@vueform/multiselect';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import newTable from '@/components/newTable.vue';
import Multiselect from 'vue-multiselect';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            permissions: [],
            tableRow: [],
            showFilters: false,
            dummyObj: {},
            headers: ['_id', 'Process Name', 'department', 'Owner'],
            businessProcess: {
                criticality: '',
                businessProcessName: '',
                ownerType: '',
                name: '',
                department: '',
                role: '',
                emailAddress: '',
                phoneNumber: '',
                systems: [],
                description:'',
                // informations: []
            },
            OriginalObjectofBP:{},
            businessProcessList: [],
            systems: [],
            users: [],
            optionsUserArray: [],
            tenantsSelect: [],
            tenants: [],
            savedInformationSets: [],
            teams: [],
            popUpOpen: false,
            ownerTypeValues: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            departmentList: [],
            editpop: false,
            systemOptions : [],
            userOptions : [],
            teamOptions : [],
            toolTipActive : false,
            toolTipText : '',
            clickOnButton:false

        };
    },
    validations() {
        return {
            businessProcess: {
                businessProcessName: { required },
                criticality: { required },
                // systems: { required },
                // ownerType: { required },
                name: { required },
                department: { required },
                role: { required },
                // emailAddress: { required, email },
                // phoneNumber: { required, numeric },
                // informations: { required }
                description:{required},
            }
        };
    },
    components: {
        newTable,
        popup,
        Multiselect,
        toolTip
    },
    computed: {
        ...mapGetters({ dbProcessCriticality:'dbProcessCriticality' }),
        ...mapGetters({ userInfo: 'userInfo' }),
        disableStatus: function (): any {
            let result;
            if (this.editpop) {
                if (_.isEqual(this.businessProcess, this.dummyObj)) {
                    result = false;
                } else {
                    result = true;
                }
            } else {
                let valueArray: any = [];
                Object.values(this.businessProcess).forEach((value: any) => {
                    valueArray.push(value);
                });
                for (let value of valueArray) {
                    if (value != '') {
                        result = true;
                    }
                }
            }
            return result;
        }
    },
    methods: {
        asyncFind(query:any){
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 1){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}

		},
        selectedBusinessOwner(){
            console.log("this.popUpData",this.businessProcess)
            if(this.businessProcess != undefined){
                this.users.forEach((obj: any) => {
                    if(obj._id == this.businessProcess.name.value){
                        this.businessProcess.role = obj.jobTitle
                    }
                });
                 
            }
        },
        
        asyncFindForTeams(query:any){
			if(query == ''){
				this.teamOptions = []
			}else if(query.length > 1){
				this.teamOptions = this.departmentList.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}

		},
        asyncFindSystems(query:any){
			if(query == ''){
				this.systemOptions = []
			}else if(query.length > 1){
				this.systemOptions = this.systems.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        getUserDetails(nameId:any){
            let mainUser = this.users.find((user:any)=>{return user._id==nameId})
            // this.businessProcess.emailAddress = mainUser.email;
            this.businessProcess.role = mainUser.jobTitle;
        },
        showPopUp() {
            this.showFilters = false;
            this.popUpOpen = true;
            Object.keys(this.businessProcess).forEach((prop) => {
                if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                    delete this.businessProcess[prop];
                } else {
                    Array.isArray(this.businessProcess[prop]) ? (this.businessProcess[prop] = []) : (this.businessProcess[prop] = '');
                }
            });
            this.editpop = false;
        },
        onCancelbtn(id: any) {
            this.v$.$reset()
            if (id == undefined) {
                Object.keys(this.businessProcess).forEach((prop) => {
                    if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                        delete this.businessProcess[prop];
                    } else {
                        Array.isArray(this.businessProcess[prop]) ? (this.businessProcess[prop] = []) : (this.businessProcess[prop] = '');
                    }
                });
                
            } else {
                this.businessProcess = { ...this.dummyObj };
            }
        },
        onClosebtn() {
            this.v$.$reset();
            this.popUpOpen = false;
            Object.keys(this.businessProcess).forEach((prop) => {
                if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                    delete this.businessProcess[prop];
                } else {
                    Array.isArray(this.businessProcess[prop]) ? (this.businessProcess[prop] = []) : (this.businessProcess[prop] = '');
                    
                }
            });
            this.dummyObj = {};
            this.editpop = false;
        },
        showEditPopUp(showObj: any) {
             console.log("BUSSINESSPROCESS",showObj)
            this.businessProcess = { ...showObj };

                console.log('qqqqq',this.businessProcess.systems)
            // });
            // this.businessProcess.push(newName)
            console.log("BUSSINESSPROCESSSSSS",this.businessProcess)
            this.dummyObj = { ...this.businessProcess };
            this.popUpOpen = false;
            this.editpop = true;
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
            console.log('optionsUserArray',this.optionsUserArray)
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                let systemResults: any =  result.data;
                for(let i = 0;i<systemResults.length ; i++){
                    if(systemResults[i]._id != ''){
                        this.systems.push({value:systemResults[i]._id,label:systemResults[i].systemName})
                    }
                }
                // this.systems =;
                console.log('GETSYSTEMS',result.data)
                this.dummySystems = {...this.systems};
                // this.systems = this.systems.map((sys: any) => {
                //     return { value: sys._id, label: sys.systemName };
                // });
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        async getAllTeams() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                for (var i = 0; i < res.data.length; i++) {
                    let a = {value:res.data[i]._id, label:res.data[i].teamName}
                    this.departmentList.push(a);
                }
            });
            console.log('this.departmentList', this.departmentList);
        },
        // getPermisssions(){
        //     this.permissions = this.userInfo.permissions[0].resources.filter((userPer: any) => {
        //         return userPer.id == 20006;
        //     });
        //     this.permissions = this.permissions[0].permissions
        //     console.log("kjeshdekjdssfe",this.permissions)
        // },
        // async getBusinessProcess() {
        //     this.businessProcessList = [];
        //     this.tableRow = [];
        //     await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`).then((result: any) => {
        //         result.data.forEach((entity: any) => {
        //             console.log("department", entity)
        //             this.businessProcessList.push(entity);
        //             var mainName = this.optionsUserArray.find((obj: any) => {
        //                 return obj.value == entity.name;
        //             });
        //             var mainDept = this.departmentList.find((obj: any) => {
        //                 console.log("mainDept", obj)
        //                 return obj.value == entity.department;
        //             });
        //             console.log("mainDept", mainDept)
        //             if (mainName == undefined) {
        //                 this.tableRow.push({ _id: entity._id, entityId: 20006, 'Business Process Name': entity.businessProcessName, Owner: entity.name, department: mainDept.label, 'Owner Type': entity.ownerType, Email: entity.emailAddress, Phone: entity.phoneNumber });
        //             } else {
        //                 this.tableRow.push({ _id: entity._id, entityId: 20006, 'Business Process Name': entity.businessProcessName, Owner: '-', department: '-', 'Owner Type': entity.ownerType, Email: entity.emailAddress, Phone: entity.phoneNumber });
        //             }

        //             if (entity.ownerType == 1) {
        //                 this.thirdPartyPrimaryCondition = true;
        //             }
        //         });
        //     });
        // },
        async getBusinessProcess() {
            this.businessProcessList = [];
            this.tableRow = [];

            try {
                const result = await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${20006}`);
                // this.$store.dispatch('updateDotsLoading',true);
                // console.log('RESSSSSSSSSSSSSSSSSSSSSSSSSS');
                // console.log(result.data);
                this.businessProcessList = result.data
                result.data.forEach((entity:any) => {
                    // console.log("entity", entity)


                    // var BPname = this.optionsUserArray.find((obj:any)=>{
                    //    return obj.value == entity.name
                    // });
                    // entity.name = BPname
                    // var BPdepartment = this.departmentList.find((obj:any)=>{
                    //     return obj.value == entity.department
                    // });
                    // entity.department = BPdepartment
                    // var BPcriticality = this.dbProcessCriticality.find((obj:any)=>{
                    //     return obj.value == entity.criticality
                    // });
                    // entity.criticality = BPcriticality

                    entity.systems.map((systemId:any,index:any)=>{
                        let systemObj = this.systems.find((obj:any)=>{return obj.value == systemId});
                        if(systemObj!=undefined){
                            entity.systems[index] = systemObj
                        }
                    })

                    const mainName = this.optionsUserArray.find((obj: any) => { 
                        return obj.value === entity.name
                    });
                    entity.name = mainName 
                    const mainDept = this.departmentList.find((obj: any) => {
                        console.log("obj", obj)
                        return obj.value === entity.department
                    });
                    entity.department = mainDept 

                    const owner = mainName ? mainName.label : 'N/A';
                    const department = mainDept ? mainDept.label : 'N/A';

                    this.tableRow.push({
                        _id: entity._id,
                        entityId: 20006,
                        'Process Name': entity.businessProcessName != undefined ? entity.businessProcessName : 'N/A',
                        Owner:owner!= undefined ? owner : 'N/A',
                        department: department!= undefined ? department : 'N/A',
                        'Owner Type': entity.ownerType!= undefined ? entity.ownerType : 'N/A',
                        Email: entity.emailAddress!= undefined ? entity.emailAddress : 'N/A',
                        Phone: entity.phoneNumber!= undefined ? entity.phoneNumber : 'N/A',
                        description:entity.description!= undefined ? entity.description : 'N/A',
                    });
                    console.log('TABLEROW',this.tableRow)
                    
                    if (entity.ownerType === 1) {
                        this.thirdPartyPrimaryCondition = true;
                    }
                });
                // this.$store.dispatch('updateDotsLoading',false);
            } catch (error) {
                // this.$store.dispatch('updateDotsLoading',false);
                console.error('Error fetching business processes:', error);
            }
            // this.businessProcessList = this.tableRow
            console.log('businessProcessList',this.businessProcessList)
            console.table(this.tableRow);
        },

        async addOrUpdate(obj: any) {
            this.OriginalObjectofBP = {...obj};
            console.log('this.OriginalObjectofBP',obj)
            console.log('this.OriginalObjectofBP',this.OriginalObjectofBP)
            this.v$.businessProcess.$reset();
            let entityId: any = 20006;
            this.v$.businessProcess.$touch();
            let comingObjectId = obj._id;

            if (!this.v$.businessProcess.$invalid) {
                this.clickOnButton = true;
                
                let dummyCopyObj:any = {...obj}

                if (comingObjectId) {
                    try{
                        delete dummyCopyObj._id;
                        dummyCopyObj.updatedAt = new Date();
                        // dummyCopyObj.criticality = dummyCopyObj.criticality.value
                        dummyCopyObj.department = dummyCopyObj.department.value
                        dummyCopyObj.name = dummyCopyObj.name.value
                        dummyCopyObj.systems = dummyCopyObj.systems.map((sys: any) => {
                        return  sys.value ;
                    });
                        await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/update/${comingObjectId}/${entityId}`, dummyCopyObj).then((result: any) => {
                            toast.info('updated', {
                                timeout: 1000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        });
                    } catch{
                            toast.error(`cannot updated`, {
                                timeout: 1000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        } finally {
                            this.clickOnButton = false;
                        }
                }
                else {
                        try{
                        dummyCopyObj.createdAt = new Date();
                        if (Object.keys(obj).includes('_id')) {
                            delete dummyCopyObj._id;
                        }
                        if (Object.keys(obj).includes('updatedAt')) {
                            delete dummyCopyObj.updatedAt;
                        }
                        // obj.criticality = obj.criticality.value
                        dummyCopyObj.department = obj.department.value
                        dummyCopyObj.name = obj.name.value
                            dummyCopyObj.systems = obj.systems.map((sys: any) => {
                            return  sys.value ;
                            });
                
                        console.log('QWERTYQWERTY',obj.systems)
                        await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/create/${entityId}`, dummyCopyObj).then((result: any) => {
                            console.log('result',result.data)
                            toast.info('created', {
                                timeout: 1000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        });
                    }catch{
                        toast.error(`cannot updated`, {
                                timeout: 1000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        
                    }finally{
                            this.clickOnButton = false;
                    }
                }
                this.v$.$reset()
                await this.getBusinessProcess();
                this.popUpOpen = false;
                this.editpop = false;
            } 
            // else {
            //     toast.error(`cannot save`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        }
    },
    async created() {
        // this.getPermissions();
        console.log("STARTTTTTTTT")
        await this.getAllTeams();
        console.log("STARTTTTTTTT TEAMSSSS")
        await this.getAllUsers();
        await this.getAllTenants();
        await this.getBusinessProcess();
    },
    async mounted() {
        console.log("STARTTTTTTTT111")
        await this.getSystems();
        //this.$store.dispatch('updateDotsLoading', true);
        emitter.off('dataTransfering');
        emitter.on('dataTransfering', (isData: any) => {
            let comingId = isData._id;
            console.log('comingId',comingId)
            let showObj = this.businessProcessList.find((obj: any) => {
                return obj._id == comingId;
            });
            // this.getBusinessProcess();
            this.showEditPopUp(showObj);
        });
         emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
});
</script>
