<template>
	<div class="overflow-x-hidden px-5 pt-3">
		<div>
			<breadCrumbs :routesArray="routesArray"></breadCrumbs>
		</div>
		<div class="flex justify-between items-center">
			<h1 class="title">Entites</h1>
			<div class="flex items-center">
				<button class="btnprimary mr-2"  @click="addNewEntity(entitiesObj, 'add')">Add</button>
				<button @click="filterOpen" class="flex" :class="entitesDataArray.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3 fontSizeInPage">
				The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified
				and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.
		</div>
		<div v-if="Object.keys(this.$route.params).includes('regionId')">
			<newTable :tableRowData="entitesDataArray" :headersData="headersForRegions" :openPopup="false" :navigate="true"></newTable>
		</div>
		<div v-else>
			<newTable :tableRowData="entitesDataArray" :headersData="headersForEntities" :openPopup="false" :navigate="true"></newTable>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>

		</div>
	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import newTable from '@/components/newTable.vue';
import breadCrumbs from '@/components/breadCrumbs.vue';
// import popup from '@/components/popup.vue'
import { emitter } from '@/main';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
	data(): any {
		return {
			headersForEntities: ['entityName', 'entityDisplayName', 'regionName', 'entityType', 'ownerName',],
			headersForRegions: ['entityName', 'entityDisplayName', 'entityType', 'ownerName',],
			showEntitesPopUp: false,
			entitesDataArray: [],
			showFilters: false,
			optionsUserArray:[],
			users:[],
			routesArray: [],
			regionDataArray :[],
			actualRegion:'',
			toolTipActive:false,
			toolTipText:''
			

		};
	},
	components: {
		newTable,
		breadCrumbs,
		toolTip
		// popup
	},

	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		addNewEntity(){
			if(Object.keys(this.$route.params).includes('regionId')){
				this.$router.push({name:'addEntityForRegions',params:{regionId : this.$route.params.regionId},query:{addAtRegions:true}})
			}else{
              this.$router.push({name:'addEntity'})
			}
		},
		async getAllUsers() {
            this.optionsUserArray = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
                for (var i = 0; i < this.users.length; i++) {
                   this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
                }
            });           
        },
		async getAllEntities(){
			this.entitesDataArray = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				if(Object.keys(this.$route.params).includes('regionId')){
					res.data.map((obj:any)=>{
						if(obj.region == this.$route.params.regionId){
							this.entitesDataArray.push(obj)
						}
					})
				}else{
					this.entitesDataArray = res.data;
				}

				this.entitesDataArray.map((entity:any)=>{
					let userDetails = this.optionsUserArray.find((obj:any)=>{return obj.value == entity.owner});  
					entity.ownerName = userDetails != undefined ? userDetails.label : 'N/A'; 
				})
				this.entitesDataArray.map((entity:any)=>{
					let userDetails = this.regionDataArray.find((obj:any)=>{return obj._id == entity.region}); 
					console.log('ww',userDetails) 
					entity.regionName = userDetails != undefined ? userDetails.region : 'N/A'; 
				})
				console.log('asd',this.entitesDataArray)
                             
            })
		},
		 async getAllRegions(){
			this.actualRegion ='';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/regions/getall`).then((res: any) => {
                console.log('sucesssss');
				this.regionDataArray = res.data;
				this.regionDataArray.map((region : any)=>{
					if(region != undefined && region._id == this.$route.params.regionId){
					 this.actualRegion = region.region;
				   }
				   console.log("ACTUAL",this.actualRegion)

				})
			});
		 },
	},
	async created(){
		await this.getAllUsers();
		await this.getAllRegions();
		await this.getAllEntities();
		if(Object.keys(this.$route.params).includes('regionId')){
			this.routesArray = [
				{ name: 'Regions', routeName: 'regions' },
				{ name: this.actualRegion, routeName: 'businessEntitesForRegion' }
			];
		 }
	},
	async mounted() {
		emitter.off('dataTransfering')
		emitter.on('dataTransfering', (data: any) => {
			console.log('lkjhsdkjlkjas', data);
			if(Object.keys(this.$route.params).includes('regionId')){
				this.$router.push({name:'editEntityForRegion', params:{regionId : data.region,entityId:data._id},query:{entity:true}})
			}
			else{
			  this.$router.push({name:'editEntity', params:{entityId:data._id}})
			}
		});

		emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
		// emitter.off('goToInnerPage')
        // emitter.on('goToInnerPage', (Data: any) => {
        //     this.$router.push({name:'editEntity',params:{entityId:Data._id}})
			
        // })
	}
});
</script>

